<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import { mainMenuToggle, userDetails } from './DataStore'
  import { onMount } from 'svelte'
  import { userConfig } from './DataStore'

  let url = ''
  let path = ''

  onMount(() => {
    url = window.location.href
    path = url.split('/')[4]

    mixpanel.track_links('#help-sidebar', 'Student Help Clicked')
  })
</script>

<nav
  class="sb-sidenav accordion sb-sidenav-light s-sidebar"
  id="sidenavAccordion"
>
  <div class="sb-sidenav-menu">
    <div class="nav">
      <div class="s-section-header">
        <span class="s-section-header-text">{$_('MAIN')}</span>
      </div>
      <div class="s-section-item" class:s-active-page={path === ''}>
        <span
          class="s-section-item-text"
          on:click={() => {
            mixpanel.track('Student My Classes Clicked')
            router.goto('/')
          }}
        >
          {$_('COURSE_LIST')}
        </span>
      </div>

      <div
        class="s-section-item"
        class:s-active-page={path === 'recording-list'}
      >
        <span
          class="s-section-item-text"
          on:click={() => {
            mixpanel.track('Student My Recordings Clicked')
            router.goto('/recording-list')
          }}
        >
          {$_('MY_RECORDINGS')}</span
        >
      </div>

      {#if $userConfig?.serverFlags?.showPremium === true}
        <div class="s-section-item" class:s-active-page={path === 'premium'}>
          <span
            class="s-section-item-text"
            on:click={() => {
              mixpanel.track('Student Premium Clicked')
              router.goto('/premium')
            }}
            ><i class="far fa-gem" />
            {$_('LEFT_MENU.PREMIUM')}</span
          >
        </div>
      {/if}

      {#if $userConfig?.serverFlags?.showPremium === true || $userConfig?.serverFlags?.showAnalytics === true}
        <div class="s-section-item" class:s-active-page={path === 'analytics'}>
          <span
            class="s-section-item-text"
            on:click={() => {
              mixpanel.track('Student Analytics Clicked')
              router.goto('/analytics')
            }}
          >
            {$_('LEFT_MENU.ANALYTICS')}</span
          >
        </div>
      {/if}

      {#if $userConfig?.serverFlags?.showExam === true}
        <div class="s-section-item" class:s-active-page={path === 'exam'}>
          <span
            class="s-section-item-text"
            on:click={() => {
              mixpanel.track('Student Exam Clicked')
              router.goto('/exam')
            }}
            ><i class="far fa-gem" />
            {$_('LEFT_MENU.EXAM')}</span
          >
        </div>
      {/if}

        <div class="s-section-item" class:s-active-page={path === 'report'}>
          <span
            class="s-section-item-text"
            on:click={() => {
              mixpanel.track('Report Clicked')
              router.goto('/report')
            }}
            ><i class="far fa-gem" />
            {$_('LEFT_MENU.SUMMARY')}</span
          >
        </div>

        <div class="s-section-item" class:s-active-page={path === 'teaching-comments'}>
          <span
            class="s-section-item-text"
            on:click={() => {
              mixpanel.track('TeachingComments Clicked')
              router.goto('/teaching-comments')
            }}
            ><i class="far fa-comments" />
            {$_('LEFT_MENU.TEACHING_COMMENTS')}</span
          >
        </div>

      <!-- <div class="s-section-header">
        <span class="s-section-header-text">{$_("LEARNING")}</span>
      </div> -->
      <!-- <div class="s-section-item" class:s-active-page={path === "course-list"}>
        <span
          class="s-section-item-text"
          on:click={() => {
            router.goto("/course-list");
          }}
        >
          {$_("COURSE_LIST")}
        </span>
      </div> -->
      <!-- <div class="s-section-item" class:s-active-page={path === "lesson-todo"}>
        <span
          class="s-section-item-text"
          on:click={() => {
            router.goto("/lesson-todo");
          }}
        >
          {$_("LESSONS_TODO")}</span
        >
      </div> -->

      <div class="s-section-header">
        <span class="s-section-header-text">{$_('OTHER')}</span>
      </div>
      <div class="s-section-item" class:s-active-page={path === 'settings'}>
        <span
          class="s-section-item-text"
          on:click={() => {
            mixpanel.track('Student Settings Clicked')
            router.goto('/settings')
          }}
        >
          {$_('SETTINGS')}
        </span>
      </div>
      <div class="s-section-item" class:s-active-page={path === 'help'}>
        <a
          id="help-sidebar"
          class="s-section-item-text"
          style="text-decoration: none;"
          role="button"
          target="_blank"
          href="https://help.oksensay.com/"
        >
          {$_('HELP')}
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="sb-sidenav-footer">
    <div class="small">{$_('LOGGED_IN_AS')}</div>
    {$userDetails?.login}
  </div> -->
</nav>

<style>
  nav {
    background-color: #eff0f1 !important;
  }

  .s-sidebar {
    background: rgba(94, 94, 97, 0.02);
    mix-blend-mode: normal;
    border-right: 0.5px solid #eff0f1;
  }

  .s-section-header {
    border-bottom: 0.5px solid #eff0f1;
    margin: 3vh 3vh 0vh 3vh;
  }

  .s-section-header-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 1em !important;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    padding-left: 2vh;

    color: #000000;
  }

  .s-section-item {
    padding-left: 3vh;
    margin-left: 2vh;
    /* --border-left: 1.5px solid rgba(196, 196, 196, 0.5); */
  }

  .s-section-item-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;

    /* --text-transform: uppercase; */

    color: #1a9e94;
    opacity: 0.9;
    cursor: pointer;
  }

  .s-active-page {
    border-left: 2px solid #1a9e94;
  }

  .s-active-page > .s-section-item-text {
    font-weight: 600;
  }
</style>
