<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4"><i class="fas fa-question-circle me-4" />{$_("HELP")}</h1>
    <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-body">Help details</div>
        </div>
      </div>
    </div>
  </div>
</main>
