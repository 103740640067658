<script>
  import { createEventDispatcher, onMount } from 'svelte'
  import TextToSpeech2 from './TextToSpeech2.svelte'
  import { mapPronToIpa } from '../../../utils/FormatUtils.svelte'
  import { _ } from 'svelte-i18n'
  import { processBlobToWav } from '../../../utils/CodecUtils.svelte'

  import * as animateScroll from 'svelte-scrollto'

  export let resultData

  export let lastRecording
  export let language
  export let courseScoringType
  export let exerciseType
  export let exerciseDetail

  const scoreThreshold = 60

  const dispatch = createEventDispatcher()

  $: if (resultData) {
    calculateResult()
  }

  let resutlAllCorrect = {
    allCount: 0,
    correctCount: 0,
    allCorrect: false,
  }

  function calculateResult() {
    resutlAllCorrect.allCorrect = true

    console.log(444, resultData?.data.NBest[0]?.Words)

    resultData?.data.NBest[0]?.Words.forEach((w) => {
      resutlAllCorrect.allCount++
      if (w.ErrorType !== 'None' || w.AccuracyScore < scoreThreshold) {
        resutlAllCorrect.allCorrect = false
      } else {
        resutlAllCorrect.correctCount++
      }
    })
  }

  let waveform
  let waveformTimeline
  let wavesurfer
  let playing = false

  let initWaveFormDone = false

  const starLevels = {
    0: 10,
    1: 30,
    2: 40,
    3: 65,
    4: 80,
    5: 90,
  }

  export let displayConfig = {
    radar: true,
    smile: true,
    studentScore: true,
    teacherScore: true,
    text: true,
    breakdownWord: true,
    breakdownPhoneme: true,
    debug: true,
  }

  onMount(async () => {})

  function tryAgain() {
    mixpanel.track('Student Results Try Again Button Clicked')
    dispatch('tryAgainExercise', {})
    //window.location.reload();
  }

  function openLesson() {
    console.log('openLesson')
    const msg = {
      text: 'PIN OK',
      pin: Number(loginDetail?.lessonId),
      userName: loginDetail?.userName,
      loginToken: loginDetail?.loginToken,
      lessonId: Number(loginDetail?.lessonId),
    }
    dispatch('openLesson', msg)
  }

  function nextExercise() {
    mixpanel.track('Student Results Next Button Clicked')
    const msg = {}
    dispatch('nextExercise', msg)
  }

  async function initWaveWorm() {
    console.log('xxx', resultData)
    initWaveFormDone = true
    waveform.innerHTML = ''
    console.log('initWaveWorm')

    //waveform = document.getElementById("waveform");

    let wavesurferOptions = {
      container: '#waveform',
      waveColor: 'violet',
      progressColor: 'purple',
      loaderColor: 'purple',
      cursorColor: 'navy',
      plugins: [
        WaveSurfer.timeline.create({
          container: '#wave-timeline',
        }),
      ],
    }

    wavesurfer = WaveSurfer.create(wavesurferOptions)

    let iOS = navigator.vendor == 'Apple Computer, Inc.'
    console.log('ios', iOS)

    if (!iOS) {
      wavesurfer.loadBlob(lastRecording.blob)
      animateScroll.scrollToBottom()
    } else {
      lastRecording.blob = await processBlobToWav(lastRecording.blob)
      wavesurfer.loadBlob(lastRecording.blob)
      animateScroll.scrollToBottom()
    }

    wavesurfer.drawer.on('click', function () {
      console.log('click wavesurfer')
      selectWordBySlice()
    })
  }

  console.log('xxx', resultData)

  function showDetailsForWords(w) {
    console.log('showDetailsForWords', w)
  }

  function showDetailsForWordsMore(w) {
    selectedWord = w
    seekToWordSlice()
  }

  function playPauseClick() {
    console.log('pauseAudio')
    wavesurfer.playPause()
    playing = wavesurfer.isPlaying()
  }

  function replayWordSlice() {
    console.log('reaplayWordSlice', selectedWord)

    let startTime = selectedWord.Offset / 10000000
    let endTime = startTime + selectedWord.Duration / 10000000

    if (startTime && endTime) {
      wavesurfer.play(startTime, endTime)
    }
  }

  function seekToWordSlice() {
    let startTime = selectedWord.Offset / 10000000
    let seekTarget = startTime / wavesurfer.getDuration()

    if (seekTarget >= 0 && seekTarget <= 1) {
      wavesurfer.seekTo(seekTarget)
    }
  }

  function selectWordBySlice() {
    let position = wavesurfer.getCurrentTime()
    resultData?.data.NBest[0]?.Words.forEach((w) => {
      let startTime = w.Offset / 10000000
      let endTime = startTime + w.Duration / 10000000
      let inside = startTime <= position && position <= endTime
      if (inside) {
        console.log(w.Word, inside)
        showDetailsForWordsMore(w)
      }
    })
  }

  function replayWord(selectedWord, i) {
    mixpanel.track('Student Results Word Bubble Button Clicked')

    let startTime = selectedWord.Offset / 10000000
    let endTime = startTime + selectedWord.Duration / 10000000

    if (startTime && endTime) {
      wavesurfer.play(startTime, endTime)
    }

    clickPlayWordTTS(selectedWord.Word)

    // #TODO [SEN-514]

    // #TODO Bouncing Text
    // window.$('span[data-id="'+i+'"]').addClass('badge-active');
    // $: ttsButtonState, window.$('span[data-id="'+i+'"]').removeClass('badge-active');
  }

  function convertErrorCodeToTest(errorType) {
    if (errorType === 'Omission') {
      return 'Word not detected'
    } else if (errorType === 'Insertion') {
      return 'Added'
    } else if (errorType === 'Mispronunciation') {
      return 'Mispronounced'
    }
  }

  let selectedWord // = JSON.parse('{"Word":"read","AccuracyScore":15,"ErrorType":"Mispronunciation","Offset":11000000,"Duration":800000,"Phonemes":[{"Phoneme":"r","AccuracyScore":0,"Offset":11000000,"Duration":200000},{"Phoneme":"eh","AccuracyScore":0,"Offset":11300000,"Duration":200000},{"Phoneme":"d","AccuracyScore":46,"Offset":11600000,"Duration":200000}]}');
</script>

{#if resultData}
  <div class="row resultHeading5">
    <div class="col-lg-6">
      <h5 style="text-align:center;">Results</h5>
    </div>
  </div>

  <div class="row justify-content-lg-center">
    <div class="col-lg-6">
      {#if displayConfig.teacherScore}
        <div class="card">
          <div class="card-body container">
            <div class="row mb-2">
              <div class="score score-overall col-6">Correct:</div>
              <div class="col-6" style="min-width=8em;">
                {#if resutlAllCorrect?.allCorrect}YES{:else}NO{/if}
              </div>
            </div>
            <div class="row mb-2">
              <div class="score score-overall col-6">Correct Score:</div>
              <div class="col-6" style="min-width=8em;">
                {#if resutlAllCorrect}{resutlAllCorrect.correctCount} / {resutlAllCorrect.allCount}{/if}
              </div>
            </div>
            <div class="row mb-2">
              <div class="score score-overall col-6">Overall Score:</div>
              <div class="col-6" style="min-width=8em;">
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[1]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[2]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[3]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[4]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[5]}
                />
              </div>
            </div>
            <div class="row">
              <div class="score col-6">Pronunciation:</div>
              <div class="col-6" style="min-width=8em;">
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[1]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[2]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[3]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[4]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[5]}
                />
              </div>
            </div>
            <!-- Read || SelfPractice mode with text -->
            {#if exerciseType === 'READ' || (exerciseType === 'SELF_PRACTICE' && exerciseDetail?.selfPracticeText && exerciseDetail?.selfPracticeText.length > 0)}
              <div class="row">
                <div class="score col-6">Word Match:</div>

                <div class="col-6" style="min-width=8em;">
                  <span
                    class="fa fa-star"
                    class:checked={Number.parseFloat(
                      resultData?.completenessScore
                    ) > starLevels[1]}
                  />
                  <span
                    class="fa fa-star"
                    class:checked={Number.parseFloat(
                      resultData?.completenessScore
                    ) > starLevels[2]}
                  />
                  <span
                    class="fa fa-star"
                    class:checked={Number.parseFloat(
                      resultData?.completenessScore
                    ) > starLevels[3]}
                  />
                  <span
                    class="fa fa-star"
                    class:checked={Number.parseFloat(
                      resultData?.completenessScore
                    ) > starLevels[4]}
                  />
                  <span
                    class="fa fa-star"
                    class:checked={Number.parseFloat(
                      resultData?.completenessScore
                    ) > starLevels[5]}
                  />
                </div>
              </div>
            {/if}
            <div class="row">
              <div class="score col-6">Fluency:</div>

              <div class="col-6" style="min-width=8em;">
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[1]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[2]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[3]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[4]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[5]}
                />
              </div>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>

  <div class="row justify-content-lg-center">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          {#each resultData?.data.NBest[0]?.Words as w, i}
            {#if w.AccuracyScore > 80 && w.ErrorType === 'None'}
              <span
                class="badge badge-excellent"
                data-id={i}
                data-word={w.Word}
                data-score={w.SensayWordScore}>{w.Word}</span
              >
            {:else if w.AccuracyScore >= scoreThreshold && w.ErrorType === 'None'}
              <span
                class="badge badge-good"
                data-id={i}
                data-word={w.Word}
                data-score={w.SensayWordScore}>{w.Word}</span
              >
            {:else if w.ErrorType === 'Mispronunciation'}
              <span
                class="badge badge-miss"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {:else if w.ErrorType === 'Omission'}
              <span
                class="badge badge-error"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {:else if w.ErrorType === 'Insertion'}<span
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}
              />
            {:else if w?.ErrorType !== 'None'}
              <span
                class="badge badge-bad"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {:else}
              <span
                class="badge badge-bad"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {/if}
          {/each}
        </div>
      </div>
    </div>
  </div>

  {#if exerciseDetail?.organizationCurrentSubscriptionLevel === 'DEMO_AMC'}
    <div class="row justify-content-lg-center topSeparator">
      <div class="col-auto">
        <button
          class="btn btn-primary record"
          on:click={initWaveWorm}
          style="font-size: 32px;"
          type="button"
          data-toggle="collapse"
          data-target="#collapseFive"
          aria-expanded="false"
          aria-controls="collapseFive"
        >
          More details ...
        </button>
      </div>
    </div>
    <div class="row topSeparator" class:initWaveFormDone={!initWaveFormDone}>
      <div class="col">
        {#if displayConfig.breakdownPhoneme}
          <div class="card">
            <div class="card-header">
              <p>
                <i class="fa-solid fa-circle-info mr-1" />Click each word to
                play it. The
                <i class="fas fa-volume-up" style="font-size: 1em" /> plays the Teacher
                voice. (First click = male voice, second click = female voice). Click
                "Play" to hear the entire recording.
              </p>
            </div>
            <div class="card-body">
              <div id="resultDetailsWithPhonemes">
                {#each resultData?.data.NBest[0]?.Words as w, i}
                  {#if w.SensayWordScore}
                    {#if w.SensayWordScore >= 90 && w.ErrorType === 'None'}
                      <span
                        class="badge badge-pill badge-excellent"
                        data-tooltip="Score: {w.SensayWordScore}"
                        on:click={() => showDetailsForWordsMore(w)}
                        >{w.Word}</span
                      >
                    {:else if w.ErrorType === 'Mispronunciation' || w.ErrorType === 'Insertion'}
                      <span
                        class="badge badge-pill badge-miss"
                        data-tooltip={convertErrorCodeToTest(w.ErrorType)}
                        on:click={() => showDetailsForWordsMore(w)}
                        >{w.Word}</span
                      >
                    {:else if w.ErrorType === 'Omission'}
                      <span
                        class="badge badge-pill badge-error"
                        data-tooltip={convertErrorCodeToTest(w.ErrorType)}
                        data-word={w.Word}
                        on:click={() => showDetailsForWordsMore(w)}>X</span
                      >
                    {:else if w.SensayWordScore >= 80}
                      <span
                        class="badge badge-pill badge-good"
                        data-tooltip="Score: {w.SensayWordScore}"
                        on:click={() => showDetailsForWordsMore(w)}
                        >{w.Word}</span
                      >
                    {:else if w.SensayWordScore >= 70}
                      <span
                        class="badge badge-pill badge-fair"
                        data-tooltip="Score: {w.SensayWordScore}"
                        on:click={() => showDetailsForWordsMore(w)}
                        >{w.Word}</span
                      >
                    {:else if w?.ErrorType != 'None'}
                      <span
                        class="badge badge-pill badge-bad"
                        data-tooltip="Score: {w.SensayWordScore} - {convertErrorCodeToTest(
                          w.ErrorType
                        )}"
                        on:click={() => showDetailsForWordsMore(w)}
                        >{w.Word}</span
                      >
                    {:else}
                      <span
                        class="badge badge-pill badge-bad"
                        data-tooltip="Score: {w.SensayWordScore}"
                        on:click={() => showDetailsForWordsMore(w)}
                        >{w.Word}</span
                      >
                    {/if}
                  {:else if w.AccuracyScore >= 90 && w.ErrorType === 'None'}
                    <span
                      class="badge badge-pill badge-excellent"
                      data-tooltip="Score: {w.AccuracyScore}"
                      on:click={() => showDetailsForWordsMore(w)}>{w.Word}</span
                    >
                  {:else if w.ErrorType === 'Mispronunciation' || w.ErrorType === 'Insertion'}
                    <span
                      class="badge badge-pill badge-miss"
                      data-tooltip={convertErrorCodeToTest(w.ErrorType)}
                      on:click={() => showDetailsForWordsMore(w)}>{w.Word}</span
                    >
                  {:else if w.ErrorType === 'Omission'}
                    <span
                      class="badge badge-pill badge-error"
                      data-tooltip={convertErrorCodeToTest(w.ErrorType)}
                      data-word={w.Word}
                      on:click={() => showDetailsForWordsMore(w)}>X</span
                    >
                  {:else if w.AccuracyScore >= 80}
                    <span
                      class="badge badge-pill badge-good"
                      data-tooltip="Score: {w.AccuracyScore}"
                      on:click={() => showDetailsForWordsMore(w)}>{w.Word}</span
                    >
                  {:else if w.AccuracyScore >= 70}
                    <span
                      class="badge badge-pill badge-fair"
                      data-tooltip="Score: {w.AccuracyScore}"
                      on:click={() => showDetailsForWordsMore(w)}>{w.Word}</span
                    >
                  {:else if w?.ErrorType != 'None'}
                    <span
                      class="badge badge-pill badge-bad"
                      data-tooltip="Score: {w.AccuracyScore} - {convertErrorCodeToTest(
                        w.ErrorType
                      )}"
                      on:click={() => showDetailsForWordsMore(w)}>{w.Word}</span
                    >
                  {:else}
                    <span
                      class="badge badge-pill badge-bad"
                      data-tooltip="Score: {w.AccuracyScore}"
                      on:click={() => showDetailsForWordsMore(w)}>{w.Word}</span
                    >
                  {/if}
                {/each}
              </div>
              <div id="waveform" bind:this={waveform} />
              <div id="wave-timeline" bind:this={waveformTimeline} />
              <div id="resultDetailsWithPhonemesDetail">
                <div class="topSeparator" style="text-align: center;">
                  <button
                    class="btn btn-primary"
                    id="reaplayPlayStart"
                    style="font-size: 1.5rem;"
                    on:click={playPauseClick}
                  >
                    {#if playing}
                      <i
                        class="fas fa-pause"
                        style="margin-right: 1rem;"
                      />Pause{:else}<i
                        class="fas fa-play"
                        style="margin-right: 1rem;"
                      />Play
                    {/if}</button
                  >
                  <TextToSpeech2
                    textToRead={resultData?.data.NBest[0].Display}
                    {language}
                  />
                </div>
                <div>
                  Word:{#if selectedWord?.ErrorType === 'Omission'}
                    X{:else}
                    <strong id="detailWord">{selectedWord?.Word || ''}</strong>
                  {/if}
                </div>
                <div>
                  Score:
                  <strong id="detailAccuracy"
                    >{#if selectedWord?.SensayWordScore}
                      {selectedWord?.SensayWordScore || ''}
                    {:else}
                      {selectedWord?.AccuracyScore || ''}
                    {/if}
                  </strong>
                </div>
                {#if selectedWord?.ErrorType != 'None'}
                  <div>
                    Area to improve:
                    <strong id="detailError"
                      >{convertErrorCodeToTest(selectedWord?.ErrorType) ||
                        ''}</strong
                    >
                  </div>
                {/if}
                <div />

                <div>
                  <div class="col" style="text-align: center;">
                    {#if selectedWord}
                      <button
                        class="btn btn-primary"
                        id="reaplayWord"
                        on:click={replayWordSlice}
                        disabled={!selectedWord?.Duration}
                        >Play: "{selectedWord?.Word || ''}"</button
                      >
                      <TextToSpeech2
                        textToRead={selectedWord?.Word}
                        {language}
                      />
                    {/if}
                  </div>
                  <table class="table">
                    <thead>
                      <th colspan="100%" style="text-align: center;" />
                    </thead>
                    <tbody id="detailPhonemes" class="detailPhonemes">
                      {#if Array.isArray(selectedWord?.Phonemes)}
                        <tr>
                          {#each selectedWord?.Phonemes as ph}
                            {#if ph?.AccuracyScore >= 90}
                              <td class="excellent">
                                {mapPronToIpa(ph?.Phoneme)}
                              </td>
                            {:else if ph?.AccuracyScore > 70}
                              <td class="good">
                                {mapPronToIpa(ph?.Phoneme)}
                              </td>
                            {:else if ph?.AccuracyScore > 50}
                              <td class="poor">
                                {mapPronToIpa(ph?.Phoneme)}
                              </td>
                            {:else}
                              <td class="bad">
                                {mapPronToIpa(ph?.Phoneme)}
                              </td>
                            {/if}
                          {/each}
                        </tr>
                        <tr>
                          {#each selectedWord?.Phonemes as ph}
                            {#if ph?.AccuracyScore >= 90}
                              <td class="excellent">
                                {ph?.AccuracyScore.toFixed(0)}
                              </td>
                            {:else if ph?.AccuracyScore > 70}
                              <td class="good">
                                {ph?.AccuracyScore.toFixed(0)}
                              </td>
                            {:else if ph?.AccuracyScore > 50}
                              <td class="poor">
                                {ph?.AccuracyScore.toFixed(0)}
                              </td>
                            {:else}
                              <td class="bad">
                                {ph?.AccuracyScore.toFixed(0)}
                              </td>
                            {/if}
                          {/each}
                        </tr>
                      {/if}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    </div>
  {/if}
{/if}

<style>
  .initWaveFormDone {
    display: none;
  }

  .topSeparator {
    margin-top: 1em;
  }

  .resultFace {
    font-size: 10em;
  }

  .excellent {
    color: #008154;
    font-size: 1em;
  }

  .good {
    color: #40ac86;
    font-size: 1em;
  }

  .poor {
    color: #f7ba31;
    font-size: 1em;
  }

  .bad {
    color: #fe5b5b;
    font-size: 1em;
  }

  .textResultPre {
    font-family: Helvetica;
    font-size: 1.5em;
    overflow: auto;
    white-space: pre-wrap;
  }

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    color: #333;
    border-radius: 10px;
    box-shadow: 2px 2px 1px silver;
  }

  [data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: #ffcc07ff;
    margin-top: -50px;
    margin-left: 20px;
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
  }

  .badge {
    margin-right: 3px;
    font-weight: 500;
    font-size: 80%;
    cursor: pointer;
  }

  .badge-excellent {
    background: #008154ff;
    color: ivory;
  }

  .badge-good {
    background: #40ac86ff;
    color: ivory;
  }

  .badge-fair {
    background: #f7ba31ff;
    color: ivory;
  }

  .badge-bad {
    background: #fe5b5bff;
    color: ivory;
  }

  .badge-error {
    background: #fe5b5bff;
    color: ivory;
    text-decoration: line-through;
  }

  .badge-miss {
    background: #fe5b5bff;
    color: ivory;
    text-decoration-color: ivory;
    text-decoration-line: underline;
    text-decoration: dashed;
  }

  .detailPhonemes td {
    text-align: center;
    padding: 1px;
  }

  .checked {
    color: orange;
  }

  .score-overall {
    font-weight: 600;
    font-size: large;
  }

  .record {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;
    border-radius: 16px;
    background: #00a094;
    color: #ffffff;
    border-color: #00a094;
  }
</style>
