<script>
  import { onMount } from 'svelte'
  import { BASE_URL } from '../../../components/DataStore'
  import { cleanCharactersForSSML } from '../../../utils/FormatUtils.svelte'
  export let textToRead
  export let language = 'en-us'
  export let slowFlag = false
  let ttsButtonState = 'starting'

  // subscription key and region for speech services.
  let subscriptionKey
  let serviceRegion = 'eastasia'
  let authorizationToken = ''
  let SpeechSDK
  let synthesizer
  let player
  let audioConfig
  let ttsToken
  let speechCounter = 0

  const languageMapping = {
    'en-US': {
      alternatives: ['en-us'],
      voiceNames: ['en-US-GuyNeural', 'en-US-JennyNeural'],
    },
    'en-GB': {
      alternatives: ['en-gb', 'en-uk', 'en-UK'],
      voiceNames: ['en-GB-LibbyNeural', 'en-GB-RyanNeural'],
    },
    'en-IN': {
      alternatives: ['en-in'],
      voiceNames: ['en-IN-NeerjaNeural', 'en-IN-PrabhatNeural'],
    },
    'en-NG': {
      alternatives: ['en-ng'],
      voiceNames: ['en-NG-AbeoNeural', 'en-NG-EzinneNeural'],
    },
    'en-AU': {
      alternatives: ['en-au'],
      voiceNames: ['en-AU-AnnetteNeural', 'en-AU-DarrenNeural'],
    },
    'zh-TW': {
      alternatives: ['zh-tw'],
      voiceNames: ['zh-TW-HsiaoChenNeural', 'zh-TW-YunJheNeural'],
    },
    'cs-CZ': {
      alternatives: ['cs-cz'],
      voiceNames: ['cs-CZ-VlastaNeural', 'cs-CZ-AntoninNeural'],
    },
    'jp-JP': {
      alternatives: ['jp-JP'],
      voiceNames: ['ja-JP-NanamiNeural', 'ja-JP-KeitaNeural'],
    },
    'zh-CN': {
      alternatives: ['zh-cn'],
      voiceNames: ['zh-CN-XiaoxiaoNeural', 'zh-CN-YunyangNeural'],
    },
    'de-DE': {
      alternatives: ['de-DE'],
      voiceNames: ['de-DE-KatjaNeural', 'de-DE-ConradNeural'],
    },
  }

  onMount(async () => {
    intialize()
  })

  async function intialize() {
    if (!!window.SpeechSDK) {
      SpeechSDK = window.SpeechSDK
      ttsButtonState = 'starting'

      await initPlayPage()
    } else {
      ttsButtonState = 'error'
      console.log('error with SpeechSDK')
    }
  }

  async function initPlayPage() {
    return fetch(BASE_URL + `public/api/azureToken2/1`)
      .then((response) => {
        if (!response.ok) {
          console.error('response error')
          throw new Error('Network response was not ok')
        }
        console.log('response ok')
        return response.text()
      })
      .then((text) => {
        ttsToken = text
        ttsButtonState = 'ready'
        return text
      })
      .catch((error) => {
        console.error('token', error)
        ttsButtonState = 'error'

        try {
          mixpanel.track('azureTTSError', error)
        } catch (error) {
          console.log('error mixpanel')
        }
      })
  }

  function playTextToSpeech() {
    if (ttsToken != null) {
      authorizationToken = ttsToken
    } else {
      console.error('missing token')

      try {
        mixpanel.track('azureTTSError', 'No Token')
      } catch (error) {
        console.log('error mixpanel')
      }
      return
    }
    let voiceName

    // if we got an authorization token, use the token. Otherwise use the provided subscription key
    let speechConfig
    if (authorizationToken) {
      speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        authorizationToken,
        serviceRegion
      )
    } else {
      if (
        subscriptionKey.value === '' ||
        subscriptionKey.value === 'subscription'
      ) {
        alert(
          'Please enter your Microsoft Cognitive Services Speech subscription key!'
        )
        ttsButtonState = 'error'
        return
      }
      speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        subscriptionKey.value,
        serviceRegion
      )
    }

    console.log('language', language)

    if (!languageMapping[language]) {
      for (const [key, value] of Object.entries(languageMapping)) {
        const found = value.alternatives.find((el) => el == language)
        if (found) {
          language = key
          console.log(found)
        }
      }
    }

    speechConfig.speechSynthesisLanguage = language

    if (languageMapping[language]) {
      let numberOfVoice = languageMapping[language].voiceNames.length
      let selectedVoice =
        languageMapping[language].voiceNames[speechCounter % 2]
      console.log('numberOfVoice', numberOfVoice)
      console.log('selectedVoice', selectedVoice)
      speechConfig.speechSynthesisVoiceName = selectedVoice
      voiceName = selectedVoice
    } else {
      console.log('Error no language is available')
    }

    speechCounter++

    //speechConfig.speechSynthesisOutputFormat =
    //  SpeechSDK.SpeechSynthesisOutputFormat.Ogg16Khz16BitMonoOpus;

    player = new SpeechSDK.SpeakerAudioDestination()
    audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player)
    synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig)

    ttsButtonState = 'playing'

    let ssml =
      `<speak xmlns="http://www.w3.org/2001/10/synthesis"
       xmlns:mstts="http://www.w3.org/2001/mstts"
       xmlns:emo="http://www.w3.org/2009/10/emotionml"
       version="1.0"
       xml:lang="` +
      language +
      `">
	<voice name="` +
      voiceName +
      `">
		<prosody rate="` +
      (slowFlag ? '-40%' : '-20%') +
      `"
		         pitch="0%">` +
      cleanCharactersForSSML(textToRead) +
      `</prosody>
	</voice>
</speak>`

    synthesizer.speakSsmlAsync(
      ssml,
      function (result) {
        console.log(result)
        if (
          result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          console.log('synthesis finished for [' + textToRead + ']')
        } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
          console.log('synthesis failed. Error detail: ' + result.errorDetails)
          ttsButtonState = 'error'
          initPlayPage()
        }
        synthesizer?.close()
        synthesizer = undefined
      },
      function (err) {
        ttsButtonState = 'error'
        console.log('Synthesizer Error', err)

        synthesizer?.close()
        synthesizer = undefined
        initPlayPage()

        try {
          mixpanel.track('azureTTSError', err)
        } catch (error) {
          console.log('error mixpanel')
        }
      }
    )

    player.onAudioEnd = function (s) {
      console.log('onAudioEnd', s)
      ttsButtonState = 'ready'
    }
  }

  export function stopTextToSpeech() {
    console.log(player)
    if (player != null) {
      player.pause()
      ttsButtonState = 'ready'
    }
  }

  function clickPlayTTS() {
    console.log('clickPlayTTS', ttsButtonState)
    if (ttsButtonState === 'ready') {
      playTextToSpeech()
      ttsButtonState = 'playing'
    } else if (ttsButtonState === 'playing') {
      stopTextToSpeech()
    }
  }
</script>

{#if ttsButtonState === 'starting'}
  TTS initializing...
{:else if ttsButtonState === 'playing'}
  <button class="btn btn-success record" on:click={clickPlayTTS} type="button">
    {#if slowFlag}
      <span
        class="spin"
        style="font-size:1.5em; padding: 0.4rem 0rem 0.4rem 0rem;">Slow</span
      >
    {:else}
      <i class="fa fa-pause" style="margin: 0px 3px;" />
    {/if}
  </button>
{:else if ttsButtonState === 'ready'}
  <button class="btn btn-primary record" on:click={clickPlayTTS} type="button">
    {#if slowFlag}
      <span style="font-size:1.5em; padding: 0.4rem 0rem 0.4rem 0rem;"
        >Slow</span
      >
    {:else}
      <i class="fa fa-headset" style="margin: 0px 3px;" />
    {/if}
  </button>
{:else if ttsButtonState === 'error'}TTS Reloading...{/if}

<style>
  .spin {
    animation-name: stretch;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes stretch {
    50% {
      color: black;
    }
  }

  .btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  .record {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #00a094;
    color: #ffffff;
    border-color: #00a094;
  }
</style>
