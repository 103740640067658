<script context="module">
  import { OggOpusDecoder } from 'ogg-opus-decoder'
  const sampleRate = 16000

  export async function processBlobToWav(blob) {
    const data = await blob.arrayBuffer()
    const uint8Array = new Uint8Array(data)
    const resultBlob = await processFile(uint8Array)
    return resultBlob
  }

  export async function processFile(oggOpusData) {
    // wait for the WASM to be compiled
    console.log(oggOpusData)
    const decoder = new OggOpusDecoder({ sampleRate: sampleRate })
    await decoder.ready
    let result = await decoder.decode(oggOpusData)

    console.log(result)

    if (result?.samplesDecoded > 0) {
      const output = createWavFile(result.channelData[0], sampleRate)
      return output
    }
  }

  function createWavFile(audioData, sampleRate) {
    const buffer = new ArrayBuffer(44 + audioData.length * 2)
    const view = new DataView(buffer)

    function writeString(view, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
      }
    }

    // RIFF identifier 'RIFF'
    writeString(view, 0, 'RIFF')
    // file length
    view.setUint32(4, 32 + audioData.length * 2, true)
    // RIFF type 'WAVE'
    writeString(view, 8, 'WAVE')
    // format chunk identifier 'fmt '
    writeString(view, 12, 'fmt ')
    // format chunk length
    view.setUint32(16, 16, true)
    // sample format (raw)
    view.setUint16(20, 1, true)
    // channel count
    view.setUint16(22, 1, true)
    // sample rate
    view.setUint32(24, sampleRate, true)
    // byte rate (sample rate * block align)
    view.setUint32(28, sampleRate * 2, true)
    // block align (channel count * bytes per sample)
    view.setUint16(32, 2, true)
    // bits per sample
    view.setUint16(34, 16, true)
    // data chunk identifier 'data'
    writeString(view, 36, 'data')
    // data chunk length
    view.setUint32(40, audioData.length * 2, true)

    for (let i = 0, offset = 44; i < audioData.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, audioData[i]))
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
    }

    return new Blob([view], { type: 'audio/wav' })
  }
</script>
