<script>
  import TopNavigation from '../components/TopNavigation.svelte'
  import LeftNavigation from '../components/LeftNavigation.svelte'
  import Footer from '../components/Footer.svelte'

  import { mainMenuToggle, userDetails } from '../components/DataStore'
  import ExamView from './Exam/ExamView.svelte'
</script>

<div class="sb-nav" class:sb-sidenav-toggled={$mainMenuToggle}>
  <TopNavigation />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftNavigation />
    </div>
    <div id="layoutSidenav_content">
      {#if $userDetails?.login}
        <ExamView />
      {/if}
      <Footer />
    </div>
  </div>
</div>
