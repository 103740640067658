<script>
  export let tooltipText
</script>

<div class="tooltip2">
  <slot />
  <span class="tooltip2text">{tooltipText}</span>
</div>

<style>
  .tooltip2 {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip2 .tooltip2text {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip2:hover .tooltip2text {
    visibility: visible;
  }
</style>
