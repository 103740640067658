<script>
    import { _ } from "svelte-i18n";
    import Loader from "../../components/Loader.svelte";
    import { getData } from "../../utils/ApiUtils.svelte";
    import { onMount } from "svelte";

    export let recordingId;

    let commentList = [];
    let commentStatus = "loading";

    onMount(async () => {
        getTeachingComments();
    });

    function getTeachingComments() {
        commentStatus = "loading";
        getData(`studentapi/publicrecordings/${recordingId}/teaching-comments`)
            .then((data) => {
                console.log("course successful", data);

                commentList = data;
                commentStatus = "ok";
            })
            .catch((error) => {
                commentStatus = "error";
                console.log("error", error);
            });
    }
</script>

<div class="row">
    {#if commentStatus === "loading"}
        <Loader />
    {:else if commentStatus === "error"}
        {$_("ERROR_LOADING_LESSON_LIST")}
    {:else}
        <div class="mb-2" aria-describedby="dataTable_info">
            <table class="table my-0 table-hover" id="dataTable">
                <thead>
                    <tr>
                        <th>#</th>
                        <th class="d-none d-md-table-cell">
                            {$_('TEACHER')}
                          </th>
                        <th class="d-none d-md-table-cell">
                            {$_("COMMENT")}
                        </th>
                        <th class="d-none d-md-table-cell">
                            {$_("GRADE")}
                        </th>
                        <th class="d-none d-md-table-cell">
                            {$_("CREATED_AT")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {#each commentList as c, i}
                        <tr class="lessonRow">
                            <th scope="row">{i + 1}</th>
                            <td>{c?.userName}</td>

                            <td>{c?.comment || ''}</td>
                            <!-- <td>{l?.exerciseKey}</td> -->
                            <td
                                >{#if c?.grade}{c?.grade || ""}{/if}</td
                            >
                            <td class="d-none d-md-table-cell">
                                {new Date(
                                    Date.parse(c?.createdAt + "Z"),
                                ).toLocaleString()}</td
                            >
                        </tr>
                    {/each}
                </tbody>
                <tfoot />
            </table>
        </div>
    {/if}
</div>
