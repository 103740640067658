<script>
  import { _, init, getLocaleFromNavigator } from 'svelte-i18n'
  import { patchData } from '../../utils/ApiUtils.svelte'
  import { userDetails } from '../../components/DataStore'

  import { onMount } from 'svelte'

  let userProfileStatus = ''
  let language
  let errorMessage = ''

  userDetails.subscribe((x) => {
    language = x?.languagePreference
    if (language !== 'en-US' && language !== 'zh-TW') {
      language = 'en-US'
    }
    console.log(444, language)
  })

  onMount(async () => {})

  function changeLanguage(event) {
    console.log('changeLanguage', event)
    if (language && (language === 'en-US' || language === 'zh-TW')) {
      init({
        fallbackLocale: 'en',
        initialLocale: language,
      })
    } else {
      init({
        fallbackLocale: 'en',
        initialLocale: getLocaleFromNavigator(),
      })
    }
  }

  function saveLanguageSettings() {
    userProfileStatus = 'loading'

    if (language === 'auto') {
      language = getLocaleFromNavigator()
    }

    let updateProfileJson = {
      languagePreference: language,
    }

    patchData('studentapi/user/language', updateProfileJson)
      .then((data) => {
        console.log('login successful', data)
        userProfileStatus = 'updated'
      })
      .catch((error) => {
        console.log('error login', error)
        userProfileStatus = 'error'
        errorMessage = 'Error saving data'
      })
  }
</script>

<div class="card mb-4">
  <div class="card-header py-3">
    <p class="text-primary m-0 font-weight-bold">
      {$_('SETTINGS_PAGE.LANGUAGE_SETTINGS')}
    </p>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <label for="languageSelect" class="mr-3"
          >{$_('SETTINGS_PAGE.SELECT_LANGUAGE')}</label
        >
        <select
          id="languageSelect"
          bind:value={language}
          on:change={changeLanguage}
        >
          <option value="auto">{$_('SETTINGS_PAGE.AUTO')}</option>
          <option value="en-US">{$_('SETTINGS_PAGE.ENGLISH')}</option>
          <option value="zh-TW">{$_('SETTINGS_PAGE.CHINESE_TW')}</option>
        </select>
      </div>
    </div>

    {#if userProfileStatus === 'updated'}
      <div class="alert alert-success with-top-margin" role="alert">
        {$_('PROFILE_UPDATED')}
      </div>
    {:else if userProfileStatus === 'loading'}
      <div class="alert alert-info with-top-margin" role="alert">
        {$_('PROCESSING')}
      </div>
    {:else if userProfileStatus === 'error'}
      <div class="alert alert-info with-top-margin" role="alert">
        Error {errorMessage}
      </div>
    {/if}

    <div class="row mt-4">
      <div class="col">
        <button class="btn btn-success" on:click={saveLanguageSettings}
          >{$_('SETTINGS_PAGE.SAVE_SETTINGS')}</button
        >
      </div>
    </div>
  </div>
</div>
