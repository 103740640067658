<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { loginToken, userDetails } from "../components/DataStore";

  import { getProfile } from "../utils/ApiCalls.svelte";
  import { postData } from "../utils/ApiUtils.svelte";
  import { setCookie } from "../utils/CookieUtils.svelte";
  import { onMount } from "svelte";

  export let qrcode;

  let loginAtemptResult = undefined;

  onMount(async () => {
    loginAction();
  });

  function loginAction() {
    console.log("loginAction", qrcode);
    loginAtemptResult = "processing";

    let loginJson = {
      qrcode: qrcode,
    };

    postData("student/authenticateQrCode", loginJson)
      .then((data) => {
        console.log("login successful", data);
        // Save data to sessionStorage
        //sessionStorage.setItem("jwtToken", data?.token);
        setCookie("jwtToken", data?.token, 1);
        loginToken.update((x) => data?.token);
        //moveToPage('index.html');
        loginAtemptResult = "ok";
        getProfile();

        router.goto("/");
      })
      .catch((error) => {
        console.log("error login", error);
        loginAtemptResult = "error";
      });
  }
</script>

<div class="bg-primary">
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="card shadow-lg border-0 rounded-lg mt-5">
                <div class="card-header">
                  <h3 class="text-center font-weight-light my-4">
                    {$_("LOGIN_QR_CODE")}
                  </h3>
                </div>
                <div class="card-footer text-center py-3">
                  {#if loginAtemptResult === "error"}
                    <div class="alert alert-danger" role="alert">
                      {$_("LOGIN_WRONG_CREDENTIALS")}
                    </div>
                    <div class="small">
                      <span
                        on:click={() => {
                          router.goto("/login");
                        }}>{$_("LOGIN")}</span
                      >
                    </div>
                  {:else if loginAtemptResult === "processing"}
                    <div class="alert alert-info" role="alert">
                      {$_("PROCESSING")}
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="layoutAuthentication_footer">
      <footer class="py-4 bg-light mt-auto">
        <div class="container-fluid px-4">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">Copyright &copy; Sensay 2023</div>
            <div>
              <a href="#">Privacy Policy</a>
              &middot;
              <a href="#">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
