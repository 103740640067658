<script>
    import { _ } from "svelte-i18n";
    import ReportComponent from "./ReportComponent.svelte";
    import StatisticsComponent from "./StatisticsComponent.svelte";
</script>

<main>
    <div class="container-fluid px-4">
        <h1 class="mt-4">{$_("COURSE_PROGRESS")}</h1>
        <div class="row">
            <StatisticsComponent></StatisticsComponent>
            <ReportComponent></ReportComponent>
        </div>
    </div>
</main>
