<script>
  import { _ } from 'svelte-i18n'
  import { init, getLocaleFromNavigator } from 'svelte-i18n'
  import ChangePassword from './ChangePassword.svelte'
  import UserProfileDetail from './UserProfileDetail.svelte'
  import { avatarImageSrc } from '../../components/DataStore.js'
  import LanguageSettings from './LanguageSettings.svelte'

  console.log('getLocaleFromNavigator', getLocaleFromNavigator())
  let language = getLocaleFromNavigator()

  function changeLanguage(event) {
    console.log('changeLanguage', event)
    if (language && (language === 'en-US' || language === 'zh-TW')) {
      init({
        fallbackLocale: 'en',
        initialLocale: language,
      })
    } else {
      init({
        fallbackLocale: 'en',
        initialLocale: getLocaleFromNavigator(),
      })
    }
  }
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4"><i class="fas fa-cogs me-4" />{$_('SETTINGS')}</h1>
    <div class="row">
      <div class="col">
        <div class="card mb-3">
          <div class="card-body text-center shadow">
            <img
              class="rounded-circle mb-3 mt-4"
              alt="avatar"
              src={$avatarImageSrc}
              width="200"
              height="200"
            />
            <div class="mb-3">
              <a
                role="button"
                class="btn btn-success"
                href="https://en.gravatar.com/"
                >{$_('SETTINGS_PAGE.CONFIGURE_GRAVATAR')}</a
              >
            </div>
          </div>
        </div>

        <LanguageSettings />
      </div>

      <div class="col-lg-8 mb-4">
        <div class="row">
          <div class="col">
            <UserProfileDetail />
            <ChangePassword />
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
