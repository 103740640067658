<script>
  import { _ } from 'svelte-i18n'
  import Loader from '../../components/Loader.svelte'
  import { getData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'

  export let courseId
  let rankingData

  let rankingStatus = 'loading'

  onMount(async () => {
    getDashboardData()
  })

  function getDashboardData() {
    getData(
      `studentapi/courses/${courseId}/student-account-ranking?lastXDays=30`
    )
      .then((data) => {
        console.log('course successful', data)

        rankingData = data
        rankingStatus = 'ok'
      })
      .catch((error) => {
        rankingStatus = 'error'
        console.log('error', error)
      })
  }
</script>

<div class="row">
  {#if rankingStatus === 'loading'}<Loader />{:else if rankingStatus === 'ok'}
    <div class="col-auto">
      <span>{$_('COURSE_RANKING_IN_30DAYS')}</span>
      <span>#{rankingData?.ranking} / {rankingData?.totalStudents}</span>
    </div>
    <div class="col-auto">
      <div class="s-progress-frame">
        <span>{$_('RECORDING_COUNT')} {rankingData?.metricsCount}</span>
      </div>
    </div>
  {/if}
</div>

<style>
</style>
