<script>
  import { onMount } from 'svelte'
  import { getData } from '../../utils/ApiUtils.svelte'

 
  export let courseId

  let containerDiv

  let courseList = []
  let courseStatus = 'loading'

  let myChart

  let option

  let startDay
  let endDay

  

  onMount(async () => {
    initStartAndEnd()
    initChart()
    getDashboardData()
  })

  function initStartAndEnd() {
    let today = new Date()
    let start = new Date(
      Date.UTC(today.getFullYear(), today.getMonth() - 4, 1, 0, 0, 0)
    )
    let end = new Date(
      Date.UTC(today.getFullYear(), today.getMonth() + 1, 0, 0, 0, 0)
    )

    startDay = start.toISOString().slice(0, 10)
    endDay = end.toISOString().slice(0, 10)
    console.log(333, startDay, endDay)
  }

  function initChart() {
    myChart = echarts.init(containerDiv, null, {
      renderer: 'svg',
      useDirtyRect: false,
    })

    option = {
      tooltip: {trigger: 'item',
    formatter: function (params) {
      console.log('i', params)
      return `
      ${new Date(
                        Date.parse(params?.data[0])
                      ).toLocaleDateString()}
      - <strong>${params?.data[1]}</strong>`
      // return `${JSON.stringify(params)}`;
    }},
      visualMap: {
        min: 0,
        max: 25,
        type: 'continuous',
        orient: 'horizontal',
        left: 'center',
        top: 5,
        inRange: {
          color: ['#c6e48b', '#196127'],
        },
      },
      calendar: {
        top: 60,
        left: 30,
        right: 30,
        cellSize: ['auto', 13],
        range: [startDay, endDay],
        itemStyle: {
          borderWidth: 0.5,
        },
        yearLabel: { show: true },
        dayLabel: {
          firstDay: 1, // start on Monday
        },
      },
      series: {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: courseList,
      },
    }

    if (option && typeof option === 'object') {
      myChart.setOption(option)
    }
  }

  function getDashboardData() {
    getData(`studentapi/dashboard/recording-statistics?lastXDays=180${courseId? '&courseId='+courseId : ''}`)
      .then((data) => {
        console.log('course successful', data)

        courseList = data.map((v) => {
          let time = v?.date
          return [
            echarts.time.format(time, '{yyyy}-{MM}-{dd}', false),
            v?.count,
          ]
        })
        console.log(888, courseList)
        courseStatus = 'ok'

        initChart()
      })
      .catch((error) => {
        courseStatus = 'error'
        console.log('error', error)
      })
  }

  function resize() {
    myChart.resize()
  }
</script>

<svelte:window on:resize={resize} />

<div class="row">
  <div class="col">
    <div
      bind:this={containerDiv}
      id="container"
      style="min-height: 200px; width:100%"
    />
  </div>
</div>
