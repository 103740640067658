<script>
    import TopNavigation from "../components/TopNavigation.svelte";
    import LeftNavigation from "../components/LeftNavigation.svelte";
    import Footer from "../components/Footer.svelte";
    import RecordingListView from "./RecordingList/RecordingListView.svelte";
  
    import { mainMenuToggle } from "../components/DataStore";
    import ReportView from "./Report/ReportView.svelte";


  </script>
  
  <div class="sb-nav" class:sb-sidenav-toggled={$mainMenuToggle}>
    <TopNavigation />
    <div id="layoutSidenav">
      <div id="layoutSidenav_nav">
        <LeftNavigation />
      </div>
      <div id="layoutSidenav_content">
        <ReportView />
        <Footer />
      </div>
    </div>
  </div>
  