<script>
  import TopNavigation from "../components/TopNavigation.svelte";
  import LeftNavigation from "../components/LeftNavigation.svelte";
  import Footer from "../components/Footer.svelte";

  import { mainMenuToggle } from "../components/DataStore";
  import TeachingCommentsView from "./TeachingComments/TeachingCommentsView.svelte";
</script>

<div class="sb-nav" class:sb-sidenav-toggled={$mainMenuToggle}>
  <TopNavigation />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftNavigation />
    </div>
    <div id="layoutSidenav_content">
      <TeachingCommentsView />
      <Footer />
    </div>
  </div>
</div>
