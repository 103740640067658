<script>
  import TopNavigation from "../components/TopNavigation.svelte";
  import LeftNavigation from "../components/LeftNavigation.svelte";
  import Footer from "../components/Footer.svelte";
  import RecordingDetailView from "./RecordingDetail/RecordingDetailView.svelte";

  import { mainMenuToggle } from "../components/DataStore";

  export let recordingId;
</script>

<div class="sb-nav" class:sb-sidenav-toggled={$mainMenuToggle}>
  <TopNavigation />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftNavigation />
    </div>
    <div id="layoutSidenav_content">
      <RecordingDetailView {recordingId} />
      <Footer />
    </div>
  </div>
</div>
