<script>
  import { _ } from "svelte-i18n";
  import { getData, postData } from "../../utils/ApiUtils.svelte";

  let changePasswordStatus = "loading";

  let errorMessage = "";

  let changePasswordOldPassword;
  let changePasswordNewPassword;
  let changePasswordConfirmPassword;

  function changePasswordSubmit() {
    if (changePasswordOldPassword === changePasswordNewPassword) {
      changePasswordStatus = "error";
      errorMessage = $_("PASSWORD_CHANGE_ERROR_MUST_BE_DIFFERENT");
      return;
    } else if (changePasswordNewPassword != changePasswordConfirmPassword) {
      changePasswordStatus = "error";
      errorMessage = $_("PASSWORD_CHANGE_ERROR_MUST_MATCH");
      return;
    }

    console.log(
      "changePassword",
      changePasswordOldPassword,
      changePasswordNewPassword,
      changePasswordConfirmPassword
    );

    let changePasswordJson = {
      oldPasswordPlain: changePasswordOldPassword,
      newPasswordPlain: changePasswordNewPassword,
    };

    changePasswordStatus = "processing";

    postData("studentapi/user/new-password", changePasswordJson)
      .then((data) => {
        console.log("login successful", data);
        changePasswordStatus = "changed";
      })
      .catch((error) => {
        console.log("error login", error);
        changePasswordStatus = "error";
        errorMessage = $_("PASSWORD_CHANGE_ERROR");
      });
  }
</script>

<div class="card shadow">
  <div class="card-header py-3">
    <p class="text-primary m-0 font-weight-bold">
      {$_("SETTINGS_PAGE.CHANGE_PASSWORD")}
    </p>
  </div>
  <div class="card-body">
    <form>
      <div class="form-row">
        <div class="col mb-2">
          <div class="form-group">
            <label for="changePassword"
              ><strong>{$_("SETTINGS_PAGE.CURRENT_PASSWORD")}</strong></label
            >
            <input
              id="changePasswordOldPassword"
              class="form-control"
              type="password"
              name="changePassword"
              autocomplete="current-password"
              bind:value={changePasswordOldPassword}
            />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="changePassword"
              ><strong>{$_("SETTINGS_PAGE.NEW_PASSWORD")}</strong></label
            >
            <input
              id="changePasswordNewPassword"
              class="form-control"
              type="password"
              name="changePassword"
              autocomplete="new-password"
              bind:value={changePasswordNewPassword}
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="changePasswordConfirm"
              ><strong>{$_("SETTINGS_PAGE.CONFIRM_NEW_PASSWORD")}</strong
              ></label
            >
            <input
              id="changePasswordConfirmPassword"
              class="form-control"
              type="password"
              name="changePasswordConfirm"
              autocomplete="new-password"
              bind:value={changePasswordConfirmPassword}
            />
          </div>
        </div>
      </div>

      {#if changePasswordStatus === "error"}
        <div class="alert alert-danger with-top-margin" role="alert">
          {errorMessage}
        </div>
      {/if}
      {#if changePasswordStatus === "processing"}
        <div class="alert alert-primary with-top-margin" role="alert">
          {$_("PROCESSING")}
        </div>
      {/if}
      {#if changePasswordStatus === "changed"}
        <div class="alert alert-success with-top-margin" role="alert">
          {$_("SETTINGS_PAGE.PASSWORD_CHANGED")}
        </div>
      {/if}

      <div class="form-group mt-4">
        <button
          class="btn btn-success"
          type="button"
          id="changePasswordButton"
          disabled={changePasswordStatus === "processing"}
          on:click={changePasswordSubmit}
          >{$_("SETTINGS_PAGE.CHANGE_PASSWORD")}</button
        >
      </div>
    </form>
  </div>
</div>
