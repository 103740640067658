<script context="module">
  export function convertSecToMin(timeInSec, defaultValue = '') {
    if (!timeInSec) {
      return defaultValue
    }
    let mins = ~~((timeInSec % 3600) / 60)
    let secs = ~~timeInSec % 60
    let formattedTime =
      (mins < 10 ? '0' : '') + mins + ':' + (secs < 10 ? '0' : '') + secs
    //console.log(formattedTime)
    return formattedTime
  }

  export function formatNumberWith4Colors(value, defaultValue = '') {
    if (value) {
      const num = Number.parseFloat(value)
      if (num) {
        return num.toFixed(0)
      }
    }
    if (defaultValue) {
      const num2 = Number.parseFloat(defaultValue)
      if (num2) {
        return num2.toFixed(0)
      }
    }
    return defaultValue
  }

  export function average(arr) {
    return arr.reduce((p, c) => p + c, 0) / arr.length
  }

  export function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime() //Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = Math.random() * 16 //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0
          d = Math.floor(d / 16)
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0
          d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
  }

  export function cleanCharactersForSSML(text) {
    text = text.replaceAll('&', '&amp;')
    text = text.replaceAll('<', '&lt;')
    text = text.replaceAll('>', '&gt;')
    return text
  }

  export function mapPronToIpa(ph) {
    const mapTable = {
      B: 'b',
      CH: 't.ʃ',
      D: 'd',
      DH: 'ð',
      DX: 'ɾ',
      F: 'f',
      G: 'g',
      H: 'h',
      HH: 'h',
      JH: 'd.ʒ',
      K: 'k',
      L: 'l',
      M: 'm',
      N: 'n',
      NG: 'ŋ',
      NX: 'ɾ̃',
      P: 'p',
      R: 'ɻ',
      S: 's',
      SH: 'ʃ',
      T: 't',
      TH: 'θ',
      V: 'v',
      W: 'w',
      WH: 'ʍ',
      J: 'j',
      Z: 'z',
      ZH: 'ʒ',
      AA: 'ɑ',
      AE: 'æ',
      AH0: 'ə',
      AH: 'ʌ',
      AO: 'ɔ',
      AOX: 'ɔ.ə',
      AU: 'ɑ.ʊ',
      AX: 'ə',
      AXR: 'ɚ',
      AW: 'aʊ',
      AI: 'ɑ.ɪ',
      AW: 'aʊ',
      AY: 'ɑ.ɪ',
      EH: 'ɛ',
      EHX: 'ɛ.ə',
      ER: 'ɜ',
      EL: 'l̩',
      EM: 'm̩',
      EN: 'n̩',
      ERR: 'ɝ',
      EI: 'e.ɪ',
      EY: 'e.ɪ',
      IH: 'ɪ',
      I: 'i',
      IY: 'i',
      O: 'o',
      OI: 'ɔ.ɪ',
      OY: 'ɔ.ɪ',
      OW: 'əʊ',
      OWX: 'o.ə',
      Q: 'ɒ',
      UH: 'ʊ',
      U: 'u',
      UW: 'uː',
      UX: 'ʉ',
      UWX: 'u.ə',
      Y: 'j',
    }
    const p = ph.toUpperCase()
    return mapTable[p] ? mapTable[p] : p
  }

  export function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }
</script>
