<script>
  import { _, getLocaleFromNavigator } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import { router } from 'tinro'
  import { postData } from '../utils/ApiUtils.svelte'
  import { setCookie } from '../utils/CookieUtils.svelte'
  import { loginToken } from '../components/DataStore'
  import { getProfile } from '../utils/ApiCalls.svelte'
  import PrivacyPolicy from './Registration/PrivacyPolicy.svelte'
  import TermsAndConditions from './Registration/TermsAndConditions.svelte'

  export let emailParam

  let firstName = ''
  let lastName = ''
  let email
  let checkTCPP = false
  let password = ''

  let isValidFirstName = undefined
  let isValidLastName = undefined
  let isValidEmail = undefined
  let isValidTCPPAgree = undefined
  let isValidPassword = undefined

  let emailElement

  let submitTry = false
  let registrationAtemptResult = undefined

  let termsAndConditionOpen = false
  let privacyPolicyOpen = false

  let show_password = false

  $: type = show_password ? 'text' : 'password'
  $: style = show_password ? 'letter-spacing: 0' : 'letter-spacing: 0.3rem;'

  onMount(() => {
    if (emailParam) {
      email = emailParam
    }
  })

  function validateEmail(value) {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  function register() {
    mixpanel.track('Student Create Account Button Clicked')
    submitTry = true
    email = email?.trim()
    email = email?.toLowerCase()

    if (email) {
      isValidEmail = validateEmail(email)
    } else {
      isValidEmail = false
    }

    if (firstName && firstName.length >= 1) {
      isValidFirstName = true
    } else {
      isValidFirstName = false
    }

    if (lastName && lastName.length >= 1) {
      isValidLastName = true
    } else {
      isValidLastName = false
    }

    if (checkTCPP === true) {
      isValidTCPPAgree = true
    } else {
      isValidTCPPAgree = false
    }

    if (password && password.length >= 6) {
      isValidPassword = true
    } else {
      isValidPassword = false
    }

    console.log('register')
    if (
      isValidEmail &&
      isValidFirstName &&
      isValidLastName &&
      isValidPassword &&
      isValidTCPPAgree
    ) {
      sendRegistration()
    }
  }

  function sendRegistration() {
    mixpanel.track('Student Create Account Button Clicked')
    mixpanel.identify(email)
    mixpanel.people.set_once({
      $email: email,
      user_role: 'student',
    })

    registrationAtemptResult = 'processing'

    let registerJson = {
      login: email,
      passwordPlain: password,
      firstName: firstName,
      lastName: lastName,
      languagePreference: getLocaleFromNavigator(),
    }

    postData('student/register', registerJson, false)
      .then((data) => {
        console.log('register successful', data)
        loginAction()
      })
      .catch((error) => {
        console.log('error register', error)
        registrationAtemptResult = 'error'
      })
  }

  function loginAction() {
    console.log('loginAction', email, password)
    registrationAtemptResult = 'processing'

    let loginJson = {
      username: email,
      password: password,
    }

    postData('student/authenticate', loginJson)
      .then((data) => {
        console.log('login successful', data)
        // Save data to sessionStorage
        //sessionStorage.setItem("jwtToken", data?.token);
        setCookie('jwtToken', data?.token)
        loginToken.update((x) => data?.token)
        //moveToPage('index.html');
        registrationAtemptResult = 'ok'
        getProfile()

        router.goto('/')
      })
      .catch((error) => {
        console.log('error login', error)
        //this.failed = true;

        registrationAtemptResult = 'error'
      })
  }
</script>

<TermsAndConditions bind:termsAndConditionOpen />
<PrivacyPolicy bind:privacyPolicyOpen />

<div class="bg-primary">
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7">
              <div class="card shadow-lg border-0 rounded-lg mt-5">
                <div class="card-header">
                  <h3 class="text-center font-weight-light my-4">
                    {$_('SENSAY_REGISTRATION')}
                  </h3>
                </div>
                <div class="card-body">
                  <form>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <div class="form-floating mb-3 mb-md-0">
                          <input
                            class="form-control"
                            id="inputFirstName"
                            type="text"
                            placeholder="Enter your first name"
                            autocomplete="given-name"
                            bind:value={firstName}
                            class:is-valid={submitTry && isValidFirstName}
                            class:is-invalid={submitTry && !isValidFirstName}
                          />
                          <label for="inputFirstName">{$_('FIRST_NAME')}</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-floating">
                          <input
                            class="form-control"
                            id="inputLastName"
                            type="text"
                            placeholder="Enter your last name"
                            autocomplete="family-name"
                            bind:value={lastName}
                            class:is-valid={submitTry && isValidLastName}
                            class:is-invalid={submitTry && !isValidLastName}
                          />
                          <label for="inputLastName">{$_('LAST_NAME')}</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-floating mb-3">
                      <input
                        class="form-control"
                        id="inputEmail"
                        type="email"
                        placeholder="name@example.com"
                        autocomplete="email"
                        readonly={emailParam}
                        bind:value={email}
                        bind:this={emailElement}
                        class:is-valid={submitTry && isValidEmail}
                        class:is-invalid={submitTry && !isValidEmail}
                      />
                      <label for="inputEmail">{$_('EMAIL_ADDRESS')}</label>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <div class="form-floating mb-3 mb-md-0">
                          {#if type === 'password'}
                            <input
                              class="form-control"
                              id="inputPassword"
                              type="password"
                              {style}
                              placeholder="Create a password"
                              autocomplete="new-password"
                              bind:value={password}
                              class:is-valid={submitTry && isValidPassword}
                              class:is-invalid={submitTry && !isValidPassword}
                            />
                          {:else}
                            <input
                              class="form-control"
                              id="inputPassword"
                              type="text"
                              {style}
                              placeholder="Create a password"
                              autocomplete="new-password"
                              bind:value={password}
                              class:is-valid={submitTry && isValidPassword}
                              class:is-invalid={submitTry && !isValidPassword}
                            />
                          {/if}
                          <label for="inputPassword">{$_('PASSWORD')}</label>
                          <div
                            class="password-visibility"
                            on:click={() => (show_password = !show_password)}
                          >
                            <span class="password-show-hide">
                              {#if show_password}
                                {$_('HIDE')}
                              {:else}
                                {$_('SHOW')}
                              {/if}
                            </span>
                          </div>
                          <div class="invalid-feedback">
                            {$_('PASSWORD_MUST_CONTAIN_AT_LEAST_6_CHARACTERS')}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-12">
                        <div class="form-floating mb-3 mb-md-0">
                          <div class="form-check">
                            <label
                              class="s-form-field-prompt"
                              style="display:inline-block"
                              for="tcAndPrivacyAgree"
                              on:click={() => {
                                checkTCPP = !checkTCPP
                              }}
                            >
                              <input
                                class="form-check-input"
                                class:is-valid={submitTry && isValidTCPPAgree}
                                class:is-invalid={submitTry &&
                                  !isValidTCPPAgree}
                                type="checkbox"
                                bind:checked={checkTCPP}
                                value="true"
                                id="checkBoxTC"
                              />
                              {$_('PRIVACY_POLICY_AND_TOS_TEXT')}
                              <span
                                class="s-span-link"
                                on:click={() => {
                                  termsAndConditionOpen = true
                                }}>{$_('TERMS_AND_CONDITIONS')}</span
                              >
                              {$_('AND')}
                              <span
                                class="s-span-link"
                                on:click={() => {
                                  privacyPolicyOpen = true
                                }}>{$_('PRIVACY_POLICY')}</span
                              >
                              <div class="valid-feedback" />
                              <div class="invalid-feedback">
                                {$_('PRIVACY_POLICY_AND_TOS_INVALID_FEEDBACK')}
                              </div></label
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mt-4 mb-0">
                      <div class="d-grid">
                        <span
                          class="btn btn-primary btn-block"
                          on:click={register}>{$_('CREATE_ACCOUNT')}</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-center py-3">
                  <div class="small">
                    <span
                      class="btn btn-link"
                      on:click={() => {
                        router.goto('/login')
                      }}>{$_('HAVE_ACCOUNT_GOTO_LOGIN')}</span
                    >
                  </div>
                </div>

                {#if registrationAtemptResult === 'error'}
                  <div class="alert alert-danger m-3" role="alert">
                    {$_('REGISTRATION_ERROR')}
                  </div>
                {:else if registrationAtemptResult === 'processing'}
                  <div class="alert alert-info m-3" role="alert">
                    {$_('PROCESSING')}
                  </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="layoutAuthentication_footer">
      <footer class="py-4 bg-light mt-auto">
        <div class="container-fluid px-4">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">{$_('COPYRIGHT_SENSAY')}</div>
            <div>
              <button
                class="btn btn-link"
                on:click={() => {
                  privacyPolicyOpen = true
                }}>{$_('PRIVACY_POLICY')}</button
              >
              &middot;
              <button
                class="btn btn-link"
                on:click={() => {
                  termsAndConditionOpen = true
                }}>{$_('TERMS_AND_CONDITIONS')}</button
              >
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>

<style>
  .s-span-link {
    text-decoration-line: underline;
    color: #00a094;
    cursor: pointer;
  }

  .password-visibility {
    height: 1.25rem;
    width: 3rem;

    float: right;
    transform: translate(-2.5em, -2.5rem);

    cursor: pointer;
  }

  .password-show-hide {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    text-decoration-line: underline;
    text-transform: uppercase;

    color: rgba(50, 50, 50, 0.8);

    padding: 0; /* reset */
    padding-bottom: 92%;
  }
</style>
