<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from '../../components/Loader.svelte'
  import { getData, postData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import UpgradeToPremium from './UpgradeToPremium.svelte'
  import { userDetails } from '../../components/DataStore'
  import CourseMarketplace from './CourseMarketplace.svelte'

  let gameList = []

  let gamesStatus = 'loading'

  let upgradeModal

  onMount(async () => {
    getGameList()
  })

  function getGameList() {
    gameList = [
      {
        gameId: 1,
        gameCode: 'boggleGame',
        gameName: 'Boggle',
        price: 'FREE',
      },
      {
        gameId: 2,
        gameCode: 'rpgGame',
        gameName: 'RPG',
        price: 'FREE',
      },
      {
        gameId: 3,
        gameCode: 'spellingGame',
        gameName: 'Spelling',
        price: 'FREE',
      },
      {
        gameId: 4,
        gameCode: 'wordGame',
        gameName: 'Words',
        price: 'FREE',
      },
    ]
    gamesStatus = 'ok'
  }

  function upgrade() {
    upgradeModal.showModal()
  }

  function viewGameDetail(id) {
    mixpanel.track('Student Opens Games', {
      gameID: id,
    })
    console.log('viewCourseDetail', id)
    router.goto(`/games/${id}/levels`)
  }
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4">{$_('PREMIUM_SECTION.SENSAY_PREMIUM_CONTENT')}</h1>
    <p>{$_('PREMIUM_SECTION.SENSAY_PREMIUM_CONTENT')}</p>
    <!-- <div><UpgradeToPremium bind:this={upgradeModal} /></div> -->
    <hr />
    <CourseMarketplace />

    <hr />
    <h2>{$_('PREMIUM_SECTION.GAMES')}</h2>

    <div class="row">
      {#if gamesStatus === 'loading'}
        <Loader />
      {:else if gamesStatus === 'error'}
        {$_('PREMIUM_SECTION.ERROR_LOADING_GAMES_LIST')}
      {:else}
        {#each gameList as game, i}
          <div class="col-xl-3 col-md-6">
            <div class="card mb-4">
              <div class="card-header">
                <i class="far fa-star" />
                {#if game?.price === 'FREE'}
                  {$_('PREMIUM_SECTION.FREE')}
                {:else}
                  {game?.price}
                {/if}

                <!-- {c.exerciseDoneCount}/{c.exerciseCount} -->
              </div>
              <div class="card-body course-card-body">{game?.gameName}</div>
              <div
                class="card-footer d-flex align-items-center justify-content-between"
              >
                <span
                  class="small stretched-link"
                  role="button"
                  on:click={() => {
                    viewGameDetail(game?.gameCode)
                  }}>{$_('PREMIUM_SECTION.START_GAME')}</span
                >
                <div class="small"><i class="fas fa-angle-right" /></div>
              </div>
            </div>
          </div>
        {:else}
          <div class="col align-self-center">
            <div class="text-center">
              <h3>{$_('PREMIUM_SECTION.NO_GAMES')}</h3>
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</main>

<style>
  .header-done {
    background-color: #00a094ff;
    color: #f4f4f4;
  }

  .header-in-progress {
    background-color: #ffcc07ff;
    color: black;
  }

  .header-new {
  }

  .course-card-body {
    min-height: 4rem;
  }
</style>
