<script>
  import { _ } from "svelte-i18n";
  import { putData } from "../../utils/ApiUtils.svelte";

  import { userDetails } from "../../components/DataStore";
  import Loader from "../../components/Loader.svelte";

  let userProfileStatus = "ok";
  let errorMessage;

  function saveUserProfile() {
    userProfileStatus = "loading";

    let updateProfileJson = {
      firstName: $userDetails?.firstName,
      lastName: $userDetails?.lastName,
    };

    putData("studentapi/user/me", updateProfileJson)
      .then((data) => {
        console.log("login successful", data);
        userProfileStatus = "updated";
      })
      .catch((error) => {
        console.log("error login", error);
        userProfileStatus = "error";
        errorMessage = "Error saving data";
      });
  }
</script>

<div class="card shadow mb-3">
  <div class="card-header py-3">
    <p class="text-primary m-0 font-weight-bold">
      {$_("SETTINGS_PAGE.USER_SETTINGS")}
    </p>
  </div>

  <div class="card-body">
    <form>
      {#if $userDetails}
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="userName"
                ><strong>{$_("SETTINGS_PAGE.USER_LOGIN")}</strong></label
              >
              <div>{$userDetails.login}</div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="first_name"><strong>{$_("FIRST_NAME")}</strong></label
              ><input
                id="profileFirstName"
                class="form-control"
                type="text"
                name="first_name"
                bind:value={$userDetails.firstName}
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="last_name"><strong>{$_("LAST_NAME")}</strong></label
              ><input
                id="profileLastName"
                class="form-control"
                type="text"
                name="last_name"
                bind:value={$userDetails.lastName}
              />
            </div>
          </div>
        </div>
      {:else}
        <Loader />
      {/if}
      {#if userProfileStatus === "updated"}
        <div class="alert alert-success with-top-margin" role="alert">
          {$_("SETTINGS_PAGE.PROFILE_UPDATED")}
        </div>
      {:else if userProfileStatus === "loading"}
        <div class="alert alert-info with-top-margin" role="alert">
          {$_("PROCESSING")}
        </div>
      {:else if userProfileStatus === "error"}
        <div class="alert alert-info with-top-margin" role="alert">
          Error {errorMessage}
        </div>
      {/if}

      <div class="form-group mt-4">
        <button
          id="buttonSaveProfile"
          class="btn btn-success"
          type="button"
          on:click={saveUserProfile}>{$_("SETTINGS_PAGE.SAVE_SETTINGS")}</button
        >
      </div>
    </form>
  </div>
</div>
