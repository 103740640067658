<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";

  function openSettings() {
    router.goto("/settings");
  }
</script>

<footer class="py-4 bg-light mt-auto">
  <div class="container-fluid px-4">
    <div class="d-flex align-items-center justify-content-between small">
      <div class="text-muted">{$_("COPYRIGHT_SENSAY")}</div>
      <!-- <div>
        <a href="">Privacy Policy</a>
        &middot;
        <a href="">Terms &amp; Conditions</a>
      </div> -->
    </div>
  </div>
</footer>
