<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import { loginToken, userDetails } from '../components/DataStore'

  import { getProfile } from '../utils/ApiCalls.svelte'
  import { postData } from '../utils/ApiUtils.svelte'
  import { setCookie } from '../utils/CookieUtils.svelte'

  import { onMount } from 'svelte'

  export let jwtToken

  let email = ''
  let submitTry = undefined
  let isValidEmail
  let resetPasswordAttemptResult = undefined
  let password = ''
  let isValidPassword

  onMount(async () => {
    console.log(333, parseJwt(jwtToken))

    email = parseJwt(jwtToken)?.loginName
  })

  function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  function resetPasswordConfirmButton() {
    submitTry = true

    if (email) {
      isValidEmail = validateEmail(email)
    } else {
      isValidEmail = false
    }

    if (!isValidEmail) {
      return
    }

    if (password && password.length >= 6) {
      isValidPassword = true
    } else {
      isValidPassword = false
    }

    if (isValidPassword) {
      resetPasswordAttemptResult = 'processing'

      let resetPasswordJson = {
        jwtToken: jwtToken,
        login: email,
        newPassword: password,
      }

      postData('student/resetPassword/callback', resetPasswordJson)
        .then((data) => {
          console.log('reset password successful', data)
          resetPasswordAttemptResult = 'ok'
          setCookie('jwtToken', data?.token, 1)
          loginToken.update((x) => data?.token)
          getProfile()

          setTimeout(function () {
            router.goto('/')
          }, 2000)
        })
        .catch((error) => {
          console.log('error reset password', error)
          resetPasswordAttemptResult = 'error'
        })
    }
  }

  function validateEmail(value) {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
</script>

<div class="bg-primary">
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="card shadow-lg border-0 rounded-lg mt-5">
                <div class="card-header text-center">
                  <img
                    class="header-logo"
                    alt="logo"
                    src="assets/img/logo_3.png"
                  />
                  <h3 class=" font-weight-light my-4">
                    {$_('RESET_PASSWORD.CHANGE_PASSWORD')}
                  </h3>
                </div>
                {#if resetPasswordAttemptResult !== 'ok'}
                  <div class="card-body">
                    <form>
                      <div class="form-floating mb-3">
                        <span class="me-1">{$_('RESET_PASSWORD.EMAIL')}:</span
                        ><span class="fw-bolder">{email}</span>
                      </div>

                      <div class="row mb-3">
                        <div class="col-md-12">
                          {$_('PASSWORD_MUST_CONTAIN_AT_LEAST_6_CHARACTERS')}
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-md-12">
                          <div class="form-floating mb-3 mb-md-0">
                            <input
                              class="form-control"
                              id="inputPassword"
                              type="password"
                              placeholder="Create a password"
                              autocomplete="new-password"
                              bind:value={password}
                              class:is-valid={submitTry && isValidPassword}
                              class:is-invalid={submitTry && !isValidPassword}
                            />
                            <label for="inputPassword">{$_('PASSWORD')}</label>
                            <div class="invalid-feedback">
                              {$_(
                                'PASSWORD_MUST_CONTAIN_AT_LEAST_6_CHARACTERS'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="d-flex align-items-center justify-content-between mt-4 mb-0"
                      >
                        <!-- <a class="small" href="password.html">Forgot Password?</a> -->
                        <span
                          class="btn btn-primary"
                          on:click={resetPasswordConfirmButton}
                          >{$_('RESET_PASSWORD.SET_PASSWORD')}</span
                        >
                      </div>
                    </form>
                  </div>
                  <div class="card-footer text-center py-3">
                    <div class="small">
                      <span
                        class="btn btn-link"
                        on:click={() => {
                          router.goto('/login')
                        }}
                        >{$_(
                          'RESET_PASSWORD.RESET_PASSWORD_BACK_TO_LOGIN'
                        )}</span
                      >
                    </div>
                    {#if resetPasswordAttemptResult === 'error'}
                      <div class="alert alert-danger" role="alert">
                        {$_('RESET_PASSWORD.ERROR')}
                      </div>
                    {:else if resetPasswordAttemptResult === 'processing'}
                      <div class="alert alert-info" role="alert">
                        {$_('PROCESSING')}
                      </div>
                    {/if}
                  </div>
                {:else}<div class="alert alert-info" role="alert">
                    {$_('RESET_PASSWORD.PASSWORD_CHANGED')}
                  </div>{/if}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="layoutAuthentication_footer">
      <footer class="py-4 bg-light mt-auto">
        <div class="container-fluid px-4">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">Copyright &copy; Sensay 2023</div>
            <!-- <div>
              <a href="#">Privacy Policy</a>
              &middot;
              <a href="#">Terms &amp; Conditions</a>
            </div> -->
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>

<style>
  .header-logo {
    width: 100%;
  }
</style>
