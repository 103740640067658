<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'

  import { postDataRawOutsideNoResponse } from '../utils/ApiUtils.svelte'

  let email = ''
  let submitTry = undefined
  let isValidEmail
  let resetPasswordAttemptResult = undefined

  function forgetPasswordButton() {
    submitTry = true
    mixpanel.track('Student Forgot Password Button Clicked')

    if (email) {
      isValidEmail = validateEmail(email)
    } else {
      isValidEmail = false
    }

    if (!isValidEmail) {
      return
    }

    let resetPasswordJson = {
      email: email,
    }
    resetPasswordAttemptResult = 'processing'

    //clickChangePage("login");

    postDataRawOutsideNoResponse(
      'student/resetPassword/init',
      resetPasswordJson,
      false
    )
      .then((data) => {
        console.log('reset password successful', data)
        resetPasswordAttemptResult = 'ok'
      })
      .catch((error) => {
        console.log('error reset password', error)
        resetPasswordAttemptResult = 'error'
      })
  }

  function validateEmail(value) {
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
</script>

<div class="bg-primary">
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="card shadow-lg border-0 rounded-lg mt-5">
                <div class="card-header text-center">
                  <img
                    class="header-logo"
                    alt="logo"
                    src="assets/img/logo_3.png"
                  />
                  <h3 class=" font-weight-light my-4">
                    {$_('RESET_PASSWORD.FORGET_PASSWORD')}
                  </h3>
                </div>
                {#if resetPasswordAttemptResult !== 'ok'}
                  <div class="card-body">
                    <div class="form-floating mb-3">
                      <input
                        class="form-control"
                        id="inputEmail"
                        type="email"
                        placeholder="name@example.com"
                        autocomplete="email"
                        class:is-valid={submitTry && isValidEmail}
                        class:is-invalid={submitTry && !isValidEmail}
                        bind:value={email}
                      />
                      <div class="valid-feedback" />
                      <div class="invalid-feedback">
                        {$_('RESET_PASSWORD.EMAIL_ADDRESS_INVALID_FEEDBACK')}
                      </div>
                      <label for="inputEmail">{$_('EMAIL_ADDRESS')}</label>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between mt-4 mb-0"
                    >
                      <!-- <a class="small" href="password.html">Forgot Password?</a> -->
                      <span
                        class="btn btn-primary"
                        on:click={forgetPasswordButton}
                        >{$_('RESET_PASSWORD.FORGET_PASSWORD')}</span
                      >
                    </div>
                  </div>
                  <div class="card-footer text-center py-3">
                    <div class="small">
                      <span
                        class="btn btn-link"
                        on:click={() => {
                          router.goto('/login')
                        }}
                        >{$_(
                          'RESET_PASSWORD.RESET_PASSWORD_BACK_TO_LOGIN'
                        )}</span
                      >
                    </div>
                    {#if resetPasswordAttemptResult === 'error'}
                      <div class="alert alert-danger" role="alert">
                        {$_('RESET_PASSWORD.ERROR')}
                      </div>
                    {:else if resetPasswordAttemptResult === 'processing'}
                      <div class="alert alert-info" role="alert">
                        {$_('PROCESSING')}
                      </div>
                    {/if}
                  </div>
                {:else}<div class="alert alert-info" role="alert">
                    {$_('RESET_PASSWORD.EMAIL_SENT')}
                  </div>{/if}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="layoutAuthentication_footer">
      <footer class="py-4 bg-light mt-auto">
        <div class="container-fluid px-4">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">Copyright &copy; Sensay 2023</div>
            <!-- <div>
              <a href="#">Privacy Policy</a>
              &middot;
              <a href="#">Terms &amp; Conditions</a>
            </div> -->
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>

<style>
  .header-logo {
    width: 100%;
  }
</style>
