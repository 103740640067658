<script context="module">
  import { init, getLocaleFromNavigator } from 'svelte-i18n'
  import {
    userDetails,
    userConfig,
    loginToken,
    avatarImageSrc,
  } from '../components/DataStore'
  import { getData, putData, logout } from './ApiUtils.svelte'
  import { getCookie } from './CookieUtils.svelte'
  import merge from 'deepmerge'

  export async function getProfile() {
    //let token = sessionStorage.getItem("jwtToken") || "";
    let token = getCookie('jwtToken')
    loginToken.update((x) => token)

    if (token) {
      await getData('studentapi/user/me')
        .then((data) => {
          console.log('profile successful', data)
          userDetails.update((x) => data)

          try {
            userConfig.update((x) => {
              x.serverFlags = JSON.parse(data['currentSubscriptionFlags'])
              return x
            })
          } catch (error) {
            console.log('Error parsing currentSubscriptionFlags', error)
          }

          changeLanguage(data?.languagePreference)

          //loadContext()

          calculateHashForGravatar(data.login)
          //router.goto("/");
        })
        .catch((error) => {
          logout()
          console.log('error courses', error)
        })
    }
  }

  async function calculateHashForGravatar(login) {
    let link = await hashwasm.md5(login)
    avatarImageSrc.update(
      (x) =>
        'https://www.gravatar.com/avatar/' +
        link.toLowerCase() +
        '?d=robohash&s=200'
    )
  }

  function changeLanguage(newLanguage) {
    if (newLanguage && (newLanguage === 'en-US' || newLanguage === 'zh-TW')) {
      init({
        fallbackLocale: 'en',
        initialLocale: newLanguage,
      })
    } else {
      init({
        fallbackLocale: 'en',
        initialLocale: getLocaleFromNavigator(),
      })
    }
  }

  export async function loadContext() {
    getData('studentapi/user/context').then((data) => {
      console.log('context', data)
      let jsonData = JSON.parse(data?.data)
      if (!jsonData?.fre?.freStatus) {
        jsonData = merge(jsonData, {
          fre: { freStatus: 'NEW', frePage: 'try' },
        })
      }
      // userContext.update((x) => jsonData)
    })
  }

  // export async function updateContext(value) {
  //   userContext.update((x) => {
  //     let userContextValue = merge(x, value)
  //     console.log(111, userContextValue)
  //     putData('api/user/context', { data: JSON.stringify(userContextValue) })
  //     return userContextValue
  //   })
  // }
</script>
