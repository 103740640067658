<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { getData } from "../../utils/ApiUtils.svelte";
  import CourseListView from "../CourseList/CourseListView.svelte";
  import { userConfig } from "../../components/DataStore";
  import LastXDaysRainbow from "./LastXDaysRainbow.svelte";
  import LastXDaysRanking from "./LastXDaysRanking.svelte";
  import LastWeekRainbow from "./LastWeekRainbow.svelte";
  import { userDetails } from "../../components/DataStore";

  let inputPin;

  let subscriptionEnd = new Date("2022-08-31").toLocaleDateString();

  let courseDetail;

  function cleanString(input) {
    return input.replace(/[\s-]+/g, "").trim();
  }

  function openPin() {
    mixpanel.track("Student Opens PIN From Teacher", {
      pin: inputPin,
    });
    console.log(999, inputPin);

    let cleanPin = cleanString(inputPin);

    if (cleanPin) {
      if (cleanPin.length === 5) {
        getData(`studentapi/lessons?key=${cleanPin}`)
          .then((data) => {
            console.log("lessons successful", data);
            router.goto("/lesson/" + data?.id);
          })
          .catch((error) => {
            lessonStatus = "error";
            console.log("error", error);
          });
      } else if (cleanPin.length === 8) {
        console.log("enrollment to course", cleanPin);
        router.goto(`/course-enrollment/${cleanPin}`);
      } else if (Number(cleanPin)) {
        router.goto("/lesson/" + cleanPin);
      } else {
        router.goto("/exercise-open/" + cleanPin);
      }
    }
  }

  
</script>

<main>
  <div class="container-fluid px-4">
    <!-- <h1 class="mt-4">{$_("DASHBOARD")}</h1> -->

    <!-- <div class="row mt-3">
      <div class="col">
        <div class="alert alert-success" role="alert">
          <i class="fas fa-smile mr-3" />
          {$_('PAYWALL.THANK_YOU', {
            values: {
              date: subscriptionEnd,
            },
          })}
        </div>
      </div>
    </div> -->
    <LastWeekRainbow />

    {#if $userConfig?.serverFlags?.showPremiumBanner === true}
      <div class="alert alert-success" role="alert">
        {$_("DASH.PREMIUM_PART1")}<a
          href="https://forms.gle/GFhvvWD2tTzDSkgQA"
          target="_blank">{$_("DASH.PREMIUM_PART_LINK")}</a
        >{$_("DASH.PREMIUM_PART2")}
      </div>
    {/if}

    <!-- <LastXDaysRainbow /> -->

    <!-- <div class="row mt-3 mb-3 justify-content-center">
      <LastXDaysRanking />
    </div> -->

    {#if !($userConfig?.serverFlags?.hidePinOnDashboard === true)}
      <div class="row mt-3">
        <div class="col">
          <div class="card mb-4">
            <div class="card-header">{$_("OPEN_PIN_FROM_TEACHER")}</div>
            <div class="card-body">
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="inputPin"
                  type="text"
                  placeholder="ABCXYZ"
                  bind:value={inputPin}
                />
                <label for="inputPin">PIN</label>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mt-4 mb-0"
              >
                <span class="btn btn-primary" on:click={openPin}
                  >{$_("OPEN")}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}

    <!-- <div class="row">
      <div class="col">
        <div class="card mb-4">
          <div class="card-header">{$_("HELP")}</div>
        </div>
      </div>
    </div> -->
  </div>
</main>

<CourseListView />
