<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from '../../components/Loader.svelte'
  import { getData, postData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import { userDetails } from '../../components/DataStore'

  let examList = []
  let examResultList = []

  let examStatus = 'loading'
  let examResultStatus = 'loading'

  onMount(() => {
    getExamList()
    getExamResultList()
  })

  function getExamResultList() {
    if ($userDetails?.login) {
      getData(
        `https://beta.api.oksensay.com/public/assessment/instance2?userId=${$userDetails?.login}`,
        true
      )
        .then((data) => {
          console.log('course successful', data)

          examResultList = data
          examResultStatus = 'ok'
        })
        .catch((error) => {
          examResultStatus = 'error'
          console.log('error', error)
        })
    }
  }

  function getExamList() {
    examList = [
      {
        examId: 1,
        examCode: 'ENG1',
        examName: 'English 1',
        price: 'FREE',
      },
      {
        examId: 2,
        examCode: 'ENG2',
        examName: 'English 2',
        price: 'FREE',
      },
    ]
    examStatus = 'ok'
  }

  function viewExamDetail(exam) {
    console.log(555, exam)
    mixpanel.track('Student Opens Exam', {
      examId: exam?.examId,
    })
    console.log('viewCourseDetail', exam?.examId)
    window.open(
      `https://assessment.oksensay.com/?assessmentId=${exam?.examCode}&userId=${
        $userDetails?.login
      }&userName=${
        $userDetails?.firstName + ' ' + $userDetails?.lastName
      }&assessmentType=${exam?.examCode}&skillId=1&cid=1`,
      '_blank'
    )
  }

  function calculateScore(instanceData) {
    let result = JSON.parse(instanceData?.reviewComment)
    let scoreOverall = (160 * result?.sumScoreTotal) / result?.sumScoreTotalMax
    // let scoreSpeaking =
    //   160 * (result?.sumScoreSpeakingTotal / result?.sumScoreSpeakingTotalMax)
    // let scoreReading =
    //   160 * (result?.sumScoreReadingTotal / result?.sumScoreReadingTotalMax)

    return `${scoreOverall.toFixed(0)} / 160`
  }

  function formatDate(v) {
    var day = new Date(Date.parse(v))
    return day.toLocaleDateString()
  }
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4">{$_('EXAM_SECTION.SENSAY_EXAM')}</h1>
    <p>{$_('EXAM_SECTION.SENSAY_EXAM_INTRODUCTION')}</p>
    <!-- <div><UpgradeToPremium bind:this={upgradeModal} /></div> -->
    <hr />
    <h2>{$_('EXAM_SECTION.EXAMS')}</h2>

    <div class="row">
      {#if examStatus === 'loading'}
        <Loader />
      {:else if examStatus === 'error'}
        {$_('EXAM_SECTION.ERROR_LOADING_EXAM_LIST')}
      {:else}
        {#each examList as exam, i}
          <div class="col-xl-3 col-md-6">
            <div class="card mb-4">
              <div class="card-header">
                <i class="far fa-star" />
                {#if exam?.price === 'FREE'}
                  {$_('PREMIUM_SECTION.FREE')}
                {:else}
                  {exam?.price}
                {/if}

                <!-- {c.exerciseDoneCount}/{c.exerciseCount} -->
              </div>
              <div class="card-body course-card-body">{exam?.examName}</div>
              <div
                class="card-footer d-flex align-items-center justify-content-between"
              >
                <span
                  class="small stretched-link"
                  role="button"
                  on:click={() => {
                    viewExamDetail(exam)
                  }}>{$_('EXAM_SECTION.START_EXAM')}</span
                >
                <div class="small"><i class="fas fa-angle-right" /></div>
              </div>
            </div>
          </div>
        {:else}
          <div class="col align-self-center">
            <div class="text-center">
              <h3>{$_('EXAM_SECTION.NO_EXAM')}</h3>
            </div>
          </div>
        {/each}
      {/if}
    </div>

    <div class="row">
      <div class="col-8-auto">
        {#if examResultStatus === 'loading'}
          <Loader />
        {:else if examResultStatus === 'error'}
          {$_('EXAM_SECTION.ERROR_LOADING_EXAM_LIST')}
        {:else}
          <table class="table">
            <tr
              ><th scope="col">#</th>
              <th scope="col">ID</th>
              <th scope="col">Assessment Definition Code</th>
              <th scope="col">Status</th>
              <th scope="col">Review Score</th>
              <th scope="col">Date</th>

              <th scope="col">Certificate</th>
            </tr>
            <tbody>
              {#each examResultList as exam, i}
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{exam?.id}</td>
                  <td>{exam?.assessmentDefinitionCode}</td>
                  <td>{exam?.status}</td>
                  <td>{calculateScore(exam)}</td>
                  <td>{formatDate(exam?.finishAt)}</td>
                  <td
                    ><a
                      href={`https://assessment.oksensay.com/#/certificate/${exam?.id}`}
                      target="_blank">Report</a
                    ></td
                  >
                </tr>
              {:else}
                {$_('EXAM_SECTION.NO_EXAM')}
              {/each}
            </tbody>
          </table>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  .header-done {
    background-color: #00a094ff;
    color: #f4f4f4;
  }

  .header-in-progress {
    background-color: #ffcc07ff;
    color: black;
  }

  .header-new {
  }

  .course-card-body {
    min-height: 4rem;
  }
</style>
