<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from '../../components/Loader.svelte'
  import { getData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'

  export let gameId

  let levelList = []
  let gameDetail

  let courseStatus = 'loading'
  let gamesStatus = 'loading'

  onMount(async () => {
    getLevelList(gameId)
    gamesStatus = 'ok'
  })

  let gamesDefinitions = {
    boggleGame: {
      gameName: 'Boggle Game',
      gameId: 'boggleGame',
      levels: [
        {
          levelId: 1,
          levelName: 'Level 1',
          difficultySettings: 1,
        },
        {
          levelId: 2,
          levelName: 'Level 2',
          difficultySettings: 2,
        },
      ],
    },

    rpgGame: {
      gameName: 'RPG Game',
      gameId: 'rpgGame',
      levels: [
        {
          levelId: 1,
          levelName: 'Level 1',
          difficultySettings: 1,
        },
      ],
    },
    spellingGame: {
      gameName: 'Spelling Game',
      gameId: 'spellingGame',
      levels: [
        {
          levelId: 1,
          levelName: 'Animals',
          difficultySettings: 2,
        },
        {
          levelId: 2,
          levelName: 'Level 8',
          difficultySettings: 3,
        },
      ],
    },
    wordGame: {
      gameName: 'Word Game',
      gameId: 'wordGame',
      levels: [
        {
          levelId: 1,
          levelName: 'Animals',
          difficultySettings: 3,
        },
        {
          levelId: 2,
          levelName: 'Level 1',
          difficultySettings: 2,
        },
        {
          levelId: 3,
          levelName: 'Level 2',
          difficultySettings: 2,
        },
        {
          levelId: 4,
          levelName: 'Level 3',
          difficultySettings: 2,
        },
        {
          levelId: 5,
          levelName: 'Level 4',
          difficultySettings: 2,
        },
        {
          levelId: 6,
          levelName: 'Level 5',
          difficultySettings: 3,
        },
        {
          levelId: 7,
          levelName: 'Level 6',
          difficultySettings: 3,
        },
        {
          levelId: 8,
          levelName: 'Level 7',
          difficultySettings: 4,
        },
        {
          levelId: 9,
          levelName: 'Level 8',
          difficultySettings: 4,
        },
        {
          levelId: 10,
          levelName: 'Restaurant',
          difficultySettings: 4,
        },
      ],
    },
  }

  function getLevelList(gameId) {
    gameDetail = gamesDefinitions[gameId]
    levelList = gamesDefinitions[gameId].levels

    courseStatus = 'ok'
  }

  function viewGameDetail(levelId) {
    mixpanel.track('Student Opens Games', {
      gameId: gameId,
      levelId: levelId,
    })
    console.log('viewCourseDetail', levelId)
    router.goto(`/games/${gameId}/levels/${levelId}`)
  }
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4">
      {$_('PREMIUM_SECTION.GAME_LEVELS')} - {gameDetail?.gameName}
    </h1>
    <hr />
    <!-- <ol class="breadcrumb mb-4">
        <li class="breadcrumb-item">{$_("COURSE_LIST")}</li>
      </ol> -->
    <div class="row">
      {#if courseStatus === 'loading'}
        <Loader />
      {:else if courseStatus === 'error'}
        {$_('PREMIUM_SECTION.ERROR_LOADING_LEVELS')}
      {:else}
        {#each levelList as l, i}
          <div class="col-xl-3 col-md-6">
            <div class="card mb-4">
              <div
                class="card-header"
                style="display: flex; justify-content: space-between;"
              >
                <span><i class="far fa-star me-1" />{l.levelId}</span>

                <!-- {c.exerciseDoneCount}/{c.exerciseCount} -->
                {#if l.difficultySettings <= 2}
                  <img
                    src="/assets/icons/chilli_mild.png"
                    width="32px"
                    alt="mild"
                  />
                {:else if l.difficultySettings === 3}
                  <img
                    src="/assets/icons/chilli_medium.png"
                    width="32px"
                    alt="medium"
                  />
                {:else if l.difficultySettings >= 4}
                  <img
                    src="/assets/icons/chilli_hot.png"
                    width="32px"
                    alt="hot"
                  />
                {/if}
              </div>
              <div class="card-body course-card-body">{l.levelName}</div>
              <div
                class="card-footer d-flex align-items-center justify-content-between"
              >
                <span
                  class="small stretched-link"
                  role="button"
                  on:click={() => {
                    viewGameDetail(l.levelId)
                  }}>{$_('PREMIUM_SECTION.OPEN_LEVEL')}</span
                >
                <div class="small"><i class="fas fa-angle-right" /></div>
              </div>
            </div>
          </div>
        {:else}
          <div class="col align-self-center">
            <div class="text-center">
              <h3>{$_('PREMIUM_SECTION.NO_LEVELS')}</h3>
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</main>

<style>
  .header-done {
    background-color: #00a094ff;
    color: #f4f4f4;
  }

  .header-in-progress {
    background-color: #ffcc07ff;
    color: black;
  }

  .header-new {
  }

  .course-card-body {
    min-height: 4rem;
  }
</style>
