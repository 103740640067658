<script>
  import TopNavigation from '../components/TopNavigation.svelte'
  import LeftNavigation from '../components/LeftNavigation.svelte'
  import Footer from '../components/Footer.svelte'
  import LessonDetailView from './LessonDetail/LessonDetailView.svelte'

  import { mainMenuToggle } from '../components/DataStore'
  export let lessonId
</script>

<div class="sb-nav" class:sb-sidenav-toggled={$mainMenuToggle}>
  <TopNavigation />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftNavigation />
    </div>
    <div id="layoutSidenav_content">
      <LessonDetailView {lessonId} />
      <Footer />
    </div>
  </div>
</div>
