<script>
</script>

<div
  class="d-none d-md-inline-block form-inline ms-auto me-2 me-md-3 my-2 my-md-0"
>
  <div class="input-group" style="display: none;">
    <label>
      <div class="input-group">
        <input
          class="form-control"
          type="text"
          placeholder="Search..."
          aria-label="Search..."
          aria-describedby="btnNavbarSearch"
        />
      </div></label
    >
  </div>
</div>

<style>
  label {
    position: relative;
  }

  label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 19px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' fill='none' viewBox='0 0 19 19'%3E%3Cpath fill='%23323232' fill-rule='evenodd' d='M8.867 2.252a6.84 6.84 0 1 0 0 13.68 6.84 6.84 0 0 0 0-13.68ZM.189 9.092a8.677 8.677 0 1 1 17.355 0 8.677 8.677 0 0 1-17.355 0Z' clip-rule='evenodd'/%3E%3Cpath fill='%23323232' fill-rule='evenodd' d='M14.788 15.012c.358-.358 0-1.224 1.67.372l1.634 1.633a.919.919 0 1 1-1.3 1.3l-1.633-1.634c-1.596-1.67-.73-1.312-.371-1.67Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
  }

  input {
    padding: 10px 35px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: 14px;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.3);
  }
</style>
