<script>
  import Modal from "sv-bootstrap-modal";

  export let privacyPolicyOpen = false;
</script>

<Modal bind:open={privacyPolicyOpen} dialogClasses="modal-lg">
  <div class="modal-header">
    <h5 class="modal-title">Privacy Policy</h5>
    <button
      type="button"
      class="close"
      on:click={() => {
        privacyPolicyOpen = false;
      }}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Privacy Policy</h2>
    Last Updated: January 1, 2021
    <p>
      Sensay respects the privacy of our users and visitors. We understand the
      responsibility and we are committed to protecting the personal information
      that you provide to us. We have therefore established this Privacy Policy
      to help you understand the information we collect, how it is being used,
      and how you can update, delete or export your information.
    </p>

    <h3>Information Collection</h3>
    <p>
      To make our services more useful to you and your data more secure, we may
      collect information on individuals that were provided to or registered
      with us, such as names, addresses, phone numbers, email addresses, user
      IDs, and user passwords. The information that we do NOT collect are credit
      card or bank account numbers. Sensay will automatically collect and store
      other information from your computer, portable devices and browser,
      including your IP address, cookie information, software and hardware
      attributes, and also information on your searches and browsing history and
      other activities you perform while using our services. We may obtain
      personal information from the company, organization or affiliate which you
      are employed. We may also obtain your personal information from other
      third parties, such as social networks when you grant permission for us to
      access your data when using our services. The services may supplement the
      information described above with information we obtain from other sources,
      including from both online and offline data providers, which we treat as
      protected personal information. You may also manage your privacy controls
      through your internet browser. In the event that we collect personal
      information from other third parties, the collecting, processing or using
      of your personal information shall be subjected to this privacy policy.
    </p>

    <h3>Information Use</h3>
    <p>
      Sensay uses the information collected to help better understand your needs
      and interests to enhance your user experience with our services. For
      example, we may use your information to help you complete a process or
      transaction, to communicate with you, to deliver products and services to
      you, to process billing, to provide ongoing support, and to offer you
      relevant resources and content. Occasionally we may use your information
      to contact you to complete surveys that we use for marketing and quality
      assurance purposes. By signing up for our services and opting-in to agree
      to this Privacy Policy, you agree to receive emails or other
      communications from us from time-to-time. You will always have the option
      to opt-out from receiving specific communications from us.
    </p>
    <p>
      Sensay may also analyze and compile statistics from the information
      collected to study usage behavior and trends, improve existing services,
      and develop new services. Although our services involve only a small
      amount of data processing of information collected. Sensay independently
      conducts the majority of these data processing activities. We may also
      share these non-personally identifiable information with third parties to
      analyze activities. Such information will be in aggregated form and not be
      personally identifiable.
    </p>
    <p>
      To help us improve our products and services, we may disclose and transfer
      your personal information to trusted third-party processors and service
      providers within or outside of the country you are in. All third-party
      processors involved have provided their commitments to protect customer’s
      personal information and have stated their compliance with data protection
      regulations. Whenever in the course of sharing information, we will ensure
      that the information is transferred in accordance with this Privacy Policy
      and as permitted by the applicable laws on data protection.
    </p>
    <p>
      Sensay offers our global products and services. We maintain servers around
      the world and information that we collect through one country may be
      transferred to our servers located in another country. Security safeguards
      are in place to facilitate the international transfer of such information.
    </p>

    <h3>Information Belonging to You</h3>
    <p>
      With respect to your personally identifiable information (such as name,
      birthday, family, education, occupation, and contact information) that you
      provide to us, you may request for us to export, update or delete your
      personal information. You may also request Sensay to send you a copy of,
      and that we stop the collection, process, and use of, the information. We
      will comply with your request and instruction in a timely manner. Sensay
      may also charge a reasonable cost for reviewing or making duplications of
      your personal information.
    </p>
    <p>
      Sensay may ask you to submit a scanned copy of your identification, such
      as student ID cards, membership certificates, etc., for the sole purposes
      of confirming your identity for registration of our services. After
      confirmation, we will systematically delete the copy of identification
      that you have submitted.
    </p>
    <p>
      You have the right not to provide your personally identifiable information
      to Sensay. In such a case, you may be unable to access certain product
      functions and services because certain information may be necessary for
      these products and services to function properly. we will maintain and use
      the personally identifiable information that you provide, until such time
      that you request us to remove or stop using such information; or when the
      purpose of collecting, using or processing your personal information under
      this Privacy Policy no longer exists.
    </p>

    <h3>Security</h3>
    <p>
      Sensay is committed to securing the privacy of your personal information.
      All Sensay products and services have strong security features and we
      continuously improve on these features to protect your personal
      information. To protect the privacy of any personal information you may
      have provided, we limit access to your personal information to employees
      that we reasonably believe requires such information to provide our
      services to you. In addition, all Sensay employees undergo confidentiality
      and privacy training.
    </p>

    <h3>Cookies, Logging and Tags</h3>
    <p>
      Like many other commercial products and services, Sensay uses standard
      cookies technology to collect information about how you use our product.
      Cookies are small amounts of data sent to your browser from a web server
      and stored on your computer’s hard drive. Cookies can help a website
      recognize repeat users and allow a website to track web usage behavior.
      Cookies work by assigning a number to the user that has no meaning outside
      of the assigning website. If a cookie is placed, thereafter when you visit
      a website, a message is sent back to the web server by the browser
      accessing the website. We also use some cookies to improve our service to
      you and personalize your web-browsing experience.
    </p>
    <p>
      You can set your browser to notify you when you are sent a cookie and you
      may also reject cookies by changing the designated settings on your web
      browser. To navigate through many parts of our platform, you do not need
      to turn on cookies. However, not utilizing cookies may prevent you from
      using certain functions and features.
    </p>

    <h3>Third-party Websites</h3>
    <p>
      Sensay’s products and services may provide links to third-party websites
      for your convenience and information. We do not control those websites or
      their privacy practices, which may differ from this Privacy Policy. Once
      you leave Sensay’s website, you should check the applicable privacy policy
      to determine how such third-party websites will handle any information
      they collect from you.
    </p>

    <h3>Modifying the Privacy Policy</h3>
    <p>
      We will review and update this Privacy Policy from time to time and will
      note the date of its most recent revision above. Your privacy rights under
      this Privacy Policy will not be reduced without prior notice and explicit
      consent from you. If we make any substantial changes of this Privacy
      Policy, we will make that information available by posting a notice on
      this site. We encourage you to review this Privacy Policy frequently to be
      informed of how we are protecting your information.
    </p>

    <h3>Questions or Suggestions</h3>
    <p>
      If you have questions or concerns about our collection, use, or disclosure
      of your personal information, please contact us here ( info@oksensay.com
      ).
    </p>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        on:click={() => {
          privacyPolicyOpen = false;
        }}>Close</button
      >
    </div>
  </div>
</Modal>
