<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { createEventDispatcher, onMount } from "svelte";
  import { getData } from "../../utils/ApiUtils.svelte";
  import {exercisePos , exerciseList} from "../../components/DataStore"

  export let exerciseDetail;

  let exerciseStatus = "loading";

  onMount(() => {
    getExerciseByLessonId(exerciseDetail?.lessonId);
  });

  function getExerciseByLessonId(lessonId) {
    getData("studentapi/lessons/" + lessonId + "/overview")
      .then((data) => {
        console.log("lessons successful", data);

        $exerciseList = data?.exerciseList;

        for (let i = 0 ; i< $exerciseList.length;i++){
          if (exerciseDetail.id === $exerciseList[i].exerciseId){

            $exercisePos = i;
            break;
          }
        }
        console.log('POS', $exerciseList, $exercisePos)
        
        
        exerciseStatus = "ok";
      })
      .catch((error) => {
        exerciseStatus = "error";
        console.log("error", error);
      });
  }

  function viewExerciseDetail(id) {
    console.log("viewLessonDetail", id);
    router.goto("/exercise-open/" + id);
  }

  function viewRecordingDetail(id) {
    console.log("viewRecordingDetail", id);
    router.goto(`/recording/${id}`);
  }

  function viewLesson(lessonId) {
    console.log("viewLesson", lessonId);
    router.goto(`/lesson/${lessonId}`);
  }
</script>

<div class="container-fluid">
  <!-- <div>{$_("CLASS")}: <strong>{exerciseDetail?.courseName}</strong></div>
  <div>{$_("LESSON")}: <strong>{exerciseDetail?.lessonName}</strong></div> -->

  <div class="row lesson-progress">
    <div>
      <button
        type="button"
        class="btn btn-up ms-1 me-1"
        on:click={() => viewLesson(exerciseDetail?.lessonId)}
      >
        <i class="fas fa-arrow-up me-1" />{$_("BACK_TO_LESSON")}
      </button>
      {#if $exerciseList}
        {#each $exerciseList as e, i}
          <button
            type="button"
            class="btn ms-1 me-1"
            class:btn-done={e.overallScore}
            class:btn-todo={!e.overallScore}
            class:btn-current={exerciseDetail.id === e.exerciseId}
            on:click={() => viewExerciseDetail(e.exerciseKey)}
          >
          E{i+1} 
          <!-- {e.exerciseTitle} -->
            {#if e.overallScore}
              <span
                on:click={() => {
                  viewRecordingDetail(e.publicRecordingId);
                }}
                class="badge bg-secondary">{e.overallScore?.toFixed(0)}</span
              >
            {/if}
          </button>
        {/each}
      {/if}
    </div>
  </div>
</div>

<style>
  .btn-current {
    border-width: 2px;
    border-color: #00a094 !important;
  }

  .btn-up {
    color: #ffffff;
    background: #00a094;
    border-radius: 14px;
    border-color: #00a094;
  }

  .btn-done {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
    border-radius: 14px;
  }
  .btn-todo {
    background-color: #d1e7dd;
    border-color: #badbcc;
    background-color: #f8f8f8;
    border-radius: 14px;
  }

  .lesson-progress {
    border: 1px;
    border-color: #9d9fa2;
    border-style: solid;
    border-radius: 5px;
    margin: 0.5em;
    padding: 0.5em;
  }
</style>
