<script>
  export let courseList
</script>

<div class="svg-wrapper">
  <svg
    height="5em"
    viewBox="0 0 65.640015 38.891563"
    version="1.1"
    id="svg6342"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs6339">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath25-1">
        <path d="M 0,120 H 190 V 0 H 0 Z" id="path23-1" />
      </clipPath>
    </defs>
    <g id="layer1" transform="translate(-46.093038,-105.3803)">
      <g
        id="g19-3"
        transform="matrix(0.35277776,0,0,-0.35277778,45.399192,145.99268)"
      >
        <g id="g21-0" clip-path="url(#clipPath25-1)">
          {#if courseList[0]?.v > 0}
            <g
              id="g27-5"
              transform="translate(174.4228,33.5327)"
              style="display:inline"
            >
              <path
                d="M 0,0 C 0,0 -6.3741571,-5.2597736 -6.5193207,1.0837107 -3.7477421,23.012654 -28.794641,71.715869 -79.551125,71.211711 -147.20641,69.949522 -155.53891,4.7058892 -154.644,-0.264 -153.01963,-9.2849631 -162.178,0 -162.178,0 c 0,44.784 36.304,81.089 81.089,81.089 C -36.305,81.089 1.0784529,44.784 1.0784529,0"
                style="fill:#da4038;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path29-5"
                sodipodi:nodetypes="cccscsc"
              />
            </g>
          {/if}
          <g id="g31-2" transform="translate(174.4228,33.5327)">
            {#if courseList[0]?.v > 0}
              <path
                d="m 1.0784529,0.17974214 c 0,0 -8.5310629,-3.28261004 -7.5977736,2.34190566 C -5.3654215,19.058327 -23.761861,70.9969 -81.168805,71.391453 -146.30771,67.792616 -153.75795,13.441503 -155.00348,0.81445283 -155.65015,-5.7414237 -162.178,0 -162.178,0 c 0,44.784 36.304,81.089 81.089,81.089 44.784,0 82.1674529,-36.125258 82.1674529,-80.90925786 z"
                style="fill:none;stroke:#da4038;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path33-1"
                sodipodi:nodetypes="cccscsc"
              />
            {:else}
              <path
                d="m 1.0784529,0.17974214 c 0,0 -8.5310629,-3.28261004 -7.5977736,2.34190566 C -5.3654215,19.058327 -23.761861,70.9969 -81.168805,71.391453 -146.30771,67.792616 -153.75795,13.441503 -155.00348,0.81445283 -155.65015,-5.7414237 -162.178,0 -162.178,0 c 0,44.784 36.304,81.089 81.089,81.089 44.784,0 82.1674529,-36.125258 82.1674529,-80.90925786 z"
                style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path33-1"
                sodipodi:nodetypes="cccscsc"
              />
            {/if}
          </g>
          {#if courseList[1]?.v > 0}
            <g id="g35-8" transform="translate(94.2759,95.054)">
              <path
                d="m 0,0 c 35.841,0 65,-29.158 65,-64.999 h 9.571 c 0,41.117 -33.453,74.57 -74.571,74.57 -41.118,0 -74.571,-33.453 -74.571,-74.57 H -65 C -65,-29.158 -35.841,0 0,0"
                style="fill:#e37d42;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path37-5"
              />
            </g>
          {/if}
          <g id="g39-5" transform="translate(94.2759,95.054)">
            {#if courseList[1]?.v > 0}
              <path
                d="m 0,0 c 35.841,0 65,-29.158 65,-64.999 h 9.571 c 0,41.117 -33.453,74.57 -74.571,74.57 -41.118,0 -74.571,-33.453 -74.571,-74.57 H -65 C -65,-29.158 -35.841,0 0,0 Z"
                style="fill:none;stroke:#e37d42;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path41-6"
              />
            {:else}
              <path
                d="m 0,0 c 35.841,0 65,-29.158 65,-64.999 h 9.571 c 0,41.117 -33.453,74.57 -74.571,74.57 -41.118,0 -74.571,-33.453 -74.571,-74.57 H -65 C -65,-29.158 -35.841,0 0,0 Z"
                style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path41-6"
              />
            {/if}
          </g>
          {#if courseList[2]?.v > 0}
            <g id="g43-1" transform="translate(94.2759,85.4837)">
              <path
                d="M 0,0 C 30.563,0 55.43,-24.865 55.43,-55.429 H 65 C 65,-19.588 35.841,9.57 0,9.57 c -35.841,0 -65,-29.158 -65,-64.999 h 9.57 C -55.43,-24.865 -30.563,0 0,0"
                style="fill:#f4d965;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path45-4"
              />
            </g>
          {/if}
          <g id="g47-4" transform="translate(94.2759,85.4837)">
            {#if courseList[2]?.v > 0}
              <path
                d="M 0,0 C 30.563,0 55.43,-24.865 55.43,-55.429 H 65 C 65,-19.588 35.841,9.57 0,9.57 c -35.841,0 -65,-29.158 -65,-64.999 h 9.57 C -55.43,-24.865 -30.563,0 0,0 Z"
                style="fill:none;stroke:#f4d965;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path49-3"
              />
            {:else}
              <path
                d="M 0,0 C 30.563,0 55.43,-24.865 55.43,-55.429 H 65 C 65,-19.588 35.841,9.57 0,9.57 c -35.841,0 -65,-29.158 -65,-64.999 h 9.57 C -55.43,-24.865 -30.563,0 0,0 Z"
                style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path49-3"
              />
            {/if}
          </g>
          {#if courseList[3]?.v > 0}
            <g id="g51-3" transform="translate(94.2759,75.9134)">
              <path
                d="M 0,0 C 25.287,0 45.859,-20.572 45.859,-45.858 H 55.43 C 55.43,-15.295 30.563,9.57 0,9.57 c -30.563,0 -55.43,-24.865 -55.43,-55.428 h 9.571 C -45.859,-20.572 -25.287,0 0,0"
                style="fill:#549e6f;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path53-7"
              />
            </g>
          {/if}
          <g id="g55-2" transform="translate(94.2759,75.9134)">
            {#if courseList[3]?.v > 0}
              <path
                d="M 0,0 C 25.287,0 45.859,-20.572 45.859,-45.858 H 55.43 C 55.43,-15.295 30.563,9.57 0,9.57 c -30.563,0 -55.43,-24.865 -55.43,-55.428 h 9.571 C -45.859,-20.572 -25.287,0 0,0 Z"
                style="fill:none;stroke:#549e6f;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path57-6"
              />
            {:else}
              <path
                d="M 0,0 C 25.287,0 45.859,-20.572 45.859,-45.858 H 55.43 C 55.43,-15.295 30.563,9.57 0,9.57 c -30.563,0 -55.43,-24.865 -55.43,-55.428 h 9.571 C -45.859,-20.572 -25.287,0 0,0 Z"
                style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path57-6"
              />
            {/if}
          </g>
          {#if courseList[4]?.v > 0}
            <g id="g59-4" transform="translate(94.2759,66.3421)">
              <path
                d="m 0,0 c 20.009,0 36.288,-16.278 36.288,-36.287 h 9.571 C 45.859,-11.001 25.287,9.571 0,9.571 c -25.287,0 -45.859,-20.572 -45.859,-45.858 h 9.571 C -36.288,-16.278 -20.009,0 0,0"
                style="fill:#82aad3;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path61-9"
              />
            </g>
          {/if}
          <g id="g63-9" transform="translate(94.2759,66.3421)">
            {#if courseList[4]?.v > 0}
              <path
                d="m 0,0 c 20.009,0 36.288,-16.278 36.288,-36.287 h 9.571 C 45.859,-11.001 25.287,9.571 0,9.571 c -25.287,0 -45.859,-20.572 -45.859,-45.858 h 9.571 C -36.288,-16.278 -20.009,0 0,0 Z"
                style="fill:none;stroke:#82aad3;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path65-2"
              />
            {:else}
              <path
                d="m 0,0 c 20.009,0 36.288,-16.278 36.288,-36.287 h 9.571 C 45.859,-11.001 25.287,9.571 0,9.571 c -25.287,0 -45.859,-20.572 -45.859,-45.858 h 9.571 C -36.288,-16.278 -20.009,0 0,0 Z"
                style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path65-2"
              />
            {/if}
          </g>
          {#if courseList[5]?.v > 0}
            <g id="g67-6" transform="translate(94.2759,56.7718)">
              <path
                d="m 0,0 c 14.732,0 26.718,-11.985 26.718,-26.717 h 9.57 C 36.288,-6.708 20.009,9.57 0,9.57 c -20.009,0 -36.288,-16.278 -36.288,-36.287 h 9.57 C -26.718,-11.985 -14.732,0 0,0"
                style="fill:#3554ee;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path69-9"
              />
            </g>
          {/if}
          <g id="g71-7" transform="translate(94.2759,56.7718)">
            {#if courseList[5]?.v > 0}
              <path
                d="m 0,0 c 14.732,0 26.718,-11.985 26.718,-26.717 h 9.57 C 36.288,-6.708 20.009,9.57 0,9.57 c -20.009,0 -36.288,-16.278 -36.288,-36.287 h 9.57 C -26.718,-11.985 -14.732,0 0,0 Z"
                style="fill:none;stroke:#3554ee;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path73-2"
              />
            {:else}
              <path
                d="m 0,0 c 14.732,0 26.718,-11.985 26.718,-26.717 h 9.57 C 36.288,-6.708 20.009,9.57 0,9.57 c -20.009,0 -36.288,-16.278 -36.288,-36.287 h 9.57 C -26.718,-11.985 -14.732,0 0,0 Z"
                style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path73-2"
              />
            {/if}
          </g>
          {#if courseList[6]?.v > 0}
            <g id="g75-5" transform="translate(94.2759,47.2014)">
              <path
                d="m 0,0 c 9.455,0 17.147,-7.692 17.147,-17.146 h 9.571 C 26.718,-2.415 14.732,9.57 0,9.57 c -14.732,0 -26.718,-11.985 -26.718,-26.716 h 9.571 C -17.147,-7.692 -9.455,0 0,0"
                style="fill:#662d91;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path77-6"
              />
            </g>
          {/if}
          <g id="g79-9" transform="translate(94.2759,47.2014)">
            {#if courseList[6]?.v > 0}
              <path
                d="m 0,0 c 9.455,0 17.147,-7.692 17.147,-17.146 h 9.571 C 26.718,-2.415 14.732,9.57 0,9.57 c -14.732,0 -26.718,-11.985 -26.718,-26.716 h 9.571 C -17.147,-7.692 -9.455,0 0,0 Z"
                style="fill:none;stroke:#662d91;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path81-4"
              />
            {:else}
              <path
                d="m 0,0 c 9.455,0 17.147,-7.692 17.147,-17.146 h 9.571 C 26.718,-2.415 14.732,9.57 0,9.57 c -14.732,0 -26.718,-11.985 -26.718,-26.716 h 9.571 C -17.147,-7.692 -9.455,0 0,0 Z"
                style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                id="path81-4"
              />
            {/if}
          </g>
          <g id="g83-8" transform="translate(80.0538,31.9329)">
            <path
              d="m 0,0 c -0.336,0 -0.666,-0.023 -0.991,-0.064 -2.632,4.123 -7.243,6.858 -12.497,6.858 -0.422,0 -0.838,-0.023 -1.251,-0.057 -3.786,7.441 -11.51,12.54 -20.431,12.54 -8.91,0 -16.625,-5.088 -20.416,-12.513 -0.301,0.018 -0.602,0.03 -0.908,0.03 -5.236,0 -9.836,-2.718 -12.471,-6.818 -0.196,0.013 -0.394,0.024 -0.593,0.024 -4.434,0 -8.029,-3.595 -8.029,-8.028 0,-4.434 3.595,-8.029 8.029,-8.029 0.199,0 0.397,0.01 0.593,0.025 2.635,-4.1 7.235,-6.819 12.471,-6.819 2.403,0 4.669,0.576 6.677,1.59 3.972,-3.305 9.077,-5.294 14.647,-5.294 5.635,0 10.791,2.037 14.782,5.41 2.061,-1.087 4.408,-1.706 6.9,-1.706 5.254,0 9.865,2.736 12.497,6.858 0.325,-0.04 0.655,-0.064 0.991,-0.064 4.434,0 8.029,3.595 8.029,8.029 C 8.029,-3.595 4.434,0 0,0"
              style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
              id="path85-3"
            />
          </g>
          <g id="g87-1" transform="translate(80.0538,31.9329)">
            <path
              d="m 0,0 c -0.336,0 -0.666,-0.023 -0.991,-0.064 -2.632,4.123 -7.243,6.858 -12.497,6.858 -0.422,0 -0.838,-0.023 -1.251,-0.057 -3.786,7.441 -11.51,12.54 -20.431,12.54 -8.91,0 -16.625,-5.088 -20.416,-12.513 -0.301,0.018 -0.602,0.03 -0.908,0.03 -5.236,0 -9.836,-2.718 -12.471,-6.818 -0.196,0.013 -0.394,0.024 -0.593,0.024 -4.434,0 -8.029,-3.595 -8.029,-8.028 0,-4.434 3.595,-8.029 8.029,-8.029 0.199,0 0.397,0.01 0.593,0.025 2.635,-4.1 7.235,-6.819 12.471,-6.819 2.403,0 4.669,0.576 6.677,1.59 3.972,-3.305 9.077,-5.294 14.647,-5.294 5.635,0 10.791,2.037 14.782,5.41 2.061,-1.087 4.408,-1.706 6.9,-1.706 5.254,0 9.865,2.736 12.497,6.858 0.325,-0.04 0.655,-0.064 0.991,-0.064 4.434,0 8.029,3.595 8.029,8.029 C 8.029,-3.595 4.434,0 0,0 Z"
              style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
              id="path89-3"
            />
          </g>
          <g id="g91-6" transform="translate(179.504,31.9329)">
            <path
              d="m 0,0 c -0.335,0 -0.666,-0.023 -0.99,-0.064 -2.633,4.123 -7.244,6.858 -12.497,6.858 -0.422,0 -0.838,-0.023 -1.251,-0.057 -3.786,7.441 -11.51,12.54 -20.431,12.54 -8.91,0 -16.627,-5.088 -20.417,-12.513 -0.3,0.018 -0.602,0.03 -0.907,0.03 -5.237,0 -9.836,-2.718 -12.472,-6.818 -0.196,0.013 -0.393,0.024 -0.593,0.024 -4.433,0 -8.028,-3.595 -8.028,-8.028 0,-4.434 3.595,-8.029 8.028,-8.029 0.2,0 0.397,0.01 0.593,0.025 2.636,-4.1 7.235,-6.819 12.472,-6.819 2.403,0 4.669,0.576 6.677,1.59 3.971,-3.305 9.076,-5.294 14.647,-5.294 5.635,0 10.79,2.037 14.781,5.41 2.061,-1.087 4.408,-1.706 6.901,-1.706 5.253,0 9.864,2.736 12.497,6.858 0.324,-0.04 0.655,-0.064 0.99,-0.064 4.434,0 8.029,3.595 8.029,8.029 C 8.029,-3.595 4.434,0 0,0"
              style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
              id="path93-8"
            />
          </g>
          <g id="g95-3" transform="translate(179.504,31.9329)">
            <path
              d="m 0,0 c -0.335,0 -0.666,-0.023 -0.99,-0.064 -2.633,4.123 -7.244,6.858 -12.497,6.858 -0.422,0 -0.838,-0.023 -1.251,-0.057 -3.786,7.441 -11.51,12.54 -20.431,12.54 -8.91,0 -16.627,-5.088 -20.417,-12.513 -0.3,0.018 -0.602,0.03 -0.907,0.03 -5.237,0 -9.836,-2.718 -12.472,-6.818 -0.196,0.013 -0.393,0.024 -0.593,0.024 -4.433,0 -8.028,-3.595 -8.028,-8.028 0,-4.434 3.595,-8.029 8.028,-8.029 0.2,0 0.397,0.01 0.593,0.025 2.636,-4.1 7.235,-6.819 12.472,-6.819 2.403,0 4.669,0.576 6.677,1.59 3.971,-3.305 9.076,-5.294 14.647,-5.294 5.635,0 10.79,2.037 14.781,5.41 2.061,-1.087 4.408,-1.706 6.901,-1.706 5.253,0 9.864,2.736 12.497,6.858 0.324,-0.04 0.655,-0.064 0.99,-0.064 4.434,0 8.029,3.595 8.029,8.029 C 8.029,-3.595 4.434,0 0,0 Z"
              style="fill:none;stroke:#a5a5a5;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
              id="path97-0"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>

<style>
  @media only screen and (max-device-width: 660px) {
    .svg-wrapper {
      display: none;
    }
  }
</style>
