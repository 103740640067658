<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import Loader from '../../components/Loader.svelte'
  import { getData } from '../../utils/ApiUtils.svelte'
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  import Tooltip from '../../components/Tooltip.svelte'

  export let courseId = ''

  let selectedCourse = writable(courseId ? courseId : '')
  let courseList = []
  let courseStatus = 'loading'

  let recordingList = []
  let recordingListPage = { number: 0, totalPages: 0, offset: 0 }
  let page = 0
  let sortColumn = 'createdAt'
  let sortDirection = -1

  let recordingStatus = 'loading'

  onMount(async () => {
    $selectedCourse = Number.parseInt(router.location.query.get('courseId'))
    sortColumn = router.location.query.get('sortColumn')
      ? router.location.query.get('sortColumn')
      : 'createdAt'
    sortDirection = router.location.query.get('sortDirection')
      ? router.location.query.get('sortDirection')
      : -1
    page = router.location.query.get('page', page)
      ? router.location.query.get('page', page)
      : 0

    getTeachingComments()
    getCourseList()

    selectedCourse.subscribe((x) => {
      router.location.query.set('courseId', x)
      getTeachingComments()
    })
  })

  function getCourseList() {
    getData('studentapi/courses/projection')
      .then((data) => {
        console.log('course successful', data)

        courseList = data
        courseStatus = 'ok'
      })
      .catch((error) => {
        courseStatus = 'error'
        console.log('error', error)
      })
  }

  function getTeachingComments(){
    recordingStatus = 'loading'
    let sort = (sortDirection < 0 ? '-' : '') + sortColumn
    getData(
      `studentapi/teaching-comments?type=R&page=${page}&size=50&sortBy=${sort}${
        $selectedCourse ? '&courseId=' + $selectedCourse : ''
      }`
    )
      .then((data) => {
        console.log('course successful', data)

        recordingList = data
        recordingStatus = 'ok'
      })
      .catch((error) => {
        recordingStatus = 'error'
        console.log('error', error)
      })
  }

  function changeOrder(column) {
    if (sortColumn === column) {
      sortDirection = -1 * sortDirection
    }
    sortColumn = column

    router.location.query.set('sortColumn', sortColumn)
    router.location.query.set('sortDirection', sortDirection)

    getTeachingComments()
  }
</script>

<main>
  <div class="container-fluid px-4">
    <h1 class="mt-4">{$_('TEACHING_COMMENTS')}</h1>


    <div class="row">
      {#if courseStatus == 'loading' && recordingStatus === 'loading'}
        <Loader />
      {:else}
        {#if courseStatus == 'loading'}
          <Loader />
        {:else if courseStatus === 'error'}
          {$_('ERROR_LOADING_TEACHING_COMMENTS')}
        {:else}
          <div class="mb-4">
            <label for="courseSelect" class="mr-3">{$_('SELECT_COURSE')}:</label
            >
            <select
              id="courseSelect"
              class="form-select"
              bind:value={$selectedCourse}
            >
              <option value="" />
              {#each courseList as c, i}
                <option value={c.courseId}>{c.courseName} </option>
              {/each}
            </select>
          </div>
        {/if}

        {#if recordingStatus === 'loading'}
          <Loader />
        {:else if recordingStatus === 'error'}
          {$_('ERROR_LOADING_LESSON_LIST')}
        {:else}
          <div class="mb-2" aria-describedby="dataTable_info">
            <table class="table my-0 table-hover" id="dataTable">
              <thead>
                <tr>
                  <th>#</th>
                  <th class="d-none d-md-table-cell">
                    {$_('TEACHER')}
                  </th>
                  <th class="d-none d-md-table-cell">
                    {$_('COMMENT')}
                  </th>
                  <th class="d-none d-md-table-cell">
                    {$_('GRADE')}
                  </th>

                  <th
                    class="d-none d-md-table-cell"
                    style="cursor:pointer"
                    on:click={() => {
                      changeOrder('createdAt')
                    }}
                  >
                    {#if sortColumn === 'createdAt'}
                      {#if sortDirection < 0}
                        <i class="fas fa-caret-down mr-1" />
                      {:else}
                        <i class="fas fa-caret-up mr-1" />
                      {/if}

                      <strong>{$_('CREATED_AT')}</strong>
                    {:else}
                      {$_('CREATED_AT')}
                    {/if}
                  </th>
                </tr>
              </thead>
              <tbody>
                {#each recordingList as c, i}
                  <tr
                    class="lessonRow"
                    style="cursor:pointer"                   
                  >
                    <th scope="row">{recordingListPage.offset + i + 1}</th>
                    <!-- <th>{c?.type}</th> -->
                    <!-- <td>{l?.id}</td> -->
                    <td>{c?.userName}</td>
                      <!-- {c?.courseId} - {c?.lessonId} - {c?.exerciseId} - {c?.publicRecordingId}</td> -->
                    <td><a href="/#/recording/{c?.publicRecordingId}">{c?.comment || ''}</a></td>
                    <!-- <td>{l?.exerciseKey}</td> -->
                    <td
                      >{#if c?.grade}{c?.grade || ''}{/if}</td
                    >
                    <td class="d-none d-md-table-cell">
                      {new Date(
                        Date.parse(c?.createdAt + 'Z')
                      ).toLocaleString()}</td
                    >
                  </tr>
                {/each}
              </tbody>
              <tfoot />
            </table>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" class:disabled={page === 0}>
                <button
                  class="page-link"
                  on:click={() => updatePage(page - 1)}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </button>
              </li>
              {#each Array(recordingListPage?.totalPages) as _, i}
                <li class="page-item" class:active={page === i}>
                  <button class="page-link" on:click={() => updatePage(i)}
                    >{i + 1}</button
                  >
                </li>
              {/each}
              <li
                class="page-item"
                class:disabled={recordingListPage?.totalPages ===
                  recordingListPage?.number + 1}
              >
                <button
                  class="page-link"
                  on:click={() => updatePage(page + 1)}
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        {/if}
      {/if}
    </div>
  </div>
</main>

<style>
  .tooltip2 {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip2 .tooltip2text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip2:hover .tooltip2text {
    visibility: visible;
  }
</style>
