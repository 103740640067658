<script>
  import { onMount } from "svelte";

  export let resultData;
  export let exerciseType;
  export let exerciseDetail;

  let chartLabels;
  let chartValues;
  let ctx;
  let chartCanvas;

  let dataParsed = {
    pronunciation: 96,
    fluency: 75,
    accuracy: 88,
    completeness: 44,
    overall: 89,
  };

  let dataRadar = {};

  onMount(async (promise) => {
    dataParsed.pronunciation = Number.parseFloat(resultData?.pronScore).toFixed(
      0
    );
    dataParsed.fluency = Number.parseFloat(resultData?.fluencyScore).toFixed(0);
    dataParsed.accuracy = Number.parseFloat(resultData?.accuracyScore).toFixed(
      0
    );
    dataParsed.completeness = Number.parseFloat(
      resultData?.completenessScore
    ).toFixed(0);
    dataParsed.overall = Number.parseFloat(resultData?.overallScore).toFixed(0);

    console.log("dataParsed", dataParsed);

    if (
      exerciseType === "READ" ||
      (exerciseType === "SELF_PRACTICE" &&
        exerciseDetail?.selfPracticeText &&
        exerciseDetail?.selfPracticeText.length > 0)
    ) {
      chartLabels = [
        "Overall (" + dataParsed.overall + ")",
        "Pronunciation (" + dataParsed.pronunciation + ")",
        "Fluency (" + dataParsed.fluency + ")",
        "Accuracy (" + dataParsed.accuracy + ")",
        "Completeness (" + dataParsed.completeness + ")",
      ];

      chartValues = [
        dataParsed.overall,
        dataParsed.pronunciation,
        dataParsed.fluency,
        dataParsed.accuracy,
        dataParsed.completeness,
      ];
    } else {
      chartLabels = [
        "Overall (" + dataParsed.overall + ")",
        "Pronunciation (" + dataParsed.pronunciation + ")",
        "Fluency (" + dataParsed.fluency + ")",
        "Accuracy (" + dataParsed.accuracy + ")",
      ];

      chartValues = [
        dataParsed.overall,
        dataParsed.pronunciation,
        dataParsed.fluency,
        dataParsed.accuracy,
      ];
    }

    ctx = chartCanvas.getContext("2d");
    var chart = new Chart(ctx, {
      type: "radar",
      options: {
        scales: {
          r: {
            beginAtZero: true,
            max: 100,
          },
        },
      },
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: "Sensay Score",
            backgroundColor: "#047f7388",
            borderColor: "#ffcc07ff",
            data: chartValues,
          },
        ],
      },
    });
  });
</script>

<div class="chart-container">
  {#key dataRadar}
    <canvas class="radar" bind:this={chartCanvas} id="myChart" />
  {/key}
</div>

<style>
  .chart-container {
    min-width: 150px;
    min-height: 150px;
    max-width: 450px;
    max-height: 450px;
    padding: 0;
    margin: auto;
    display: block;
  }
</style>
