<script>
  import { createEventDispatcher, onMount } from "svelte";
  import {
    postFileHeaders,
    postJsonFile,
    postJsonFileGetText,
  } from "../Utils.svelte";
  import {
    BASE_URL,
    userDetails,
    apiToken,
  } from "../../../components/DataStore";
  import { similarity } from "../utilities/SimilarityUtils.svelte";
  import ky from "ky";
  import { postData } from "../../../utils/ApiUtils.svelte";
  import { getNewTokenFn } from "./Upload/UploadUtils.svelte";

  export let exerciseDetail;
  export let lastRecording;
  export let loginDetail;
  export let resultData

  let processingStatus = "new";

  const dispatch = createEventDispatcher();

  let soundEffect;

  onMount(async () => {
    soundEffect = new Howl({
      src: ["./assets/audio/prel_musical_65.mp3"],
      volume: 0.5,
      preload: true,
    });
  });

  async function submitResult() {
    let startTime = performance.now();
    console.log("submitResult", lastRecording, exerciseDetail, loginDetail);
    if (!lastRecording) {
      return;
    }
    processingStatus = "processing";

    let audioFileName = generateAudioFileName();
    await uploadMedia(audioFileName);
    await getNewTokenFn();
    await callSensayApi(audioFileName);
    let endTime = performance.now();
    ky.post(BASE_URL + "public/api/log-action", {
      json: {
        source: "SA",
        userInfo: $userDetails.login,
        action: "UP2",
        actionStep: "OK",
        duration: endTime - startTime,
        msg: `Login: ${$userDetails.login}, Key: ${exerciseDetail?.key}`,
      },
    });
  }

  function generateAudioFileName() {
    // let ymd = new Date().format('YYYYMMDD')
    let fileName = crypto.randomUUID() + ".ogg";

    return fileName;
  }

  async function uploadMedia(audioFileName) {
    try {
      const searchParams = new URLSearchParams();
      searchParams.set("uploadType", "media");
      searchParams.set("name", "recordings/" + audioFileName);
      searchParams.set("key", "AIzaSyBgFT_wbOWOauPZpCWoBiRVGmgFdfHvr6o");

      let urlValue = new URL(
        "https://storage.googleapis.com/upload/storage/v1/b/shuoshuo/o",
      );
      urlValue.search = new URLSearchParams(searchParams).toString();

      await ky
        .post(urlValue, {
          body: lastRecording.blob,
          headers: { "Content-Type": "audio/wave" },
          retry: {
            limit: 3,
            methods: ['post'],
            statusCodes: [400, 401, 429, 502],
          },
          timeout: 120000,
        })
        .then((data) => {
          console.log("postFile", data);
        });
    } catch (error) {
      processingStatus = "failure";
      let errCode = error?.response?.status;
      let errMsg = await error?.response?.text();
      let errPayload = { code: errCode, msg: errMsg, response: error.response, error: JSON.stringify(error, Object.getOwnPropertyNames(error)) };
      ky.post(BASE_URL + "public/api/log-action", {
        json: {
          source: "SA",
          userInfo: $userDetails.login,
          action: "UPLOAD_FILE",
          actionStep: "ERROR",
          msg: JSON.stringify(errPayload),          
        },
      });
    }
  }

  async function callSensayApi(audioFileName) {

    let sensayData = {
      exerciseKey: exerciseDetail?.key,
      exerciseType: exerciseDetail?.type,
      studentToken: loginDetail.loginToken,
      studentName: loginDetail.userName,
      fileName: audioFileName,
      data: resultData?.data,
      similarityValue: resultData?.similarityScore,
      accuracyScore: resultData?.accuracyScore,
      fluencyScore: resultData?.fluencyScore,
      completenessScore: resultData?.completenessScore,
      pronScore: resultData?.pronScore,
      wordCount: resultData?.wordCount,
      wordPerMinute: resultData?.wordPerMinute,
      overallScore: resultData?.overallScore,
      durationSecond: resultData?.durationSecond,
    };

    await postData("studentapi/publicRecordings", sensayData)
      .then((data) => {
        console.log("send data to sensay", data);
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
        ky.post(BASE_URL + "public/api/log-action", {
          json: {
            source: "SA",
            userInfo: $userDetails.login,
            action: "API",
            actionStep: "ERROR",
            msg: JSON.stringify(error),
          },
        });
      });

    dispatch("message", {
      text: "Submitted",
      processResultData: sensayData,
    });
    processingStatus = "finished";

    soundEffect.play();

    callHooks();
  }

  

  function callHooks() {
    sendDiscord();
  }

  function sendDiscord() {
    let messageData = {
      username: "Student Account Recording Bot",
      embeds: [
        {
          color: 14499904,
          title: "Student Account Recording",
          fields: [
            {
              name: "Student Name",
              value: loginDetail?.userName,
            },
            {
              name: "Key",
              value: exerciseDetail?.key,
            },
            {
              name: "Overall Score",
              value: resultData?.overallScore,
            },
          ],
        },
      ],
    };

    postJsonFileGetText(
      "https://discord.com/api/webhooks/996701532147957760/wJqIDWJNits-oiOXV4YrUeFgw9ofNQlRf4hjSHfJF4jQ1eHgfVbUcC3Y0ymGDkZj3PMj",
      { "Content-Type": "application/json" },
      {},
      messageData,
    )
      .then((data) => {
        console.log("postJsonFile", data);
      })
      .catch((error) => {
        console.log("error calling webhook", error);
      });

    window.parent.postMessage("RECORDING_DONE", "*");
  }

  function openLesson() {
    console.log("openLesson");
    const msg = {
      text: "PIN OK",
      pin: Number(loginDetail?.lessonId),
      userName: loginDetail?.userName,
      loginToken: loginDetail?.loginToken,
      lessonId: Number(loginDetail?.lessonId),
    };
    dispatch("openLesson", msg);
  }

  function tryAgain() {
    dispatch("tryAgainExercise", {});
    //window.location.reload();
  }

  function nextExercise() {
    const msg = {};
    dispatch("nextExercise", msg);
  }
</script>

{#if lastRecording}
  {#if processingStatus === "new"}
  <button class="btn btn-primary record" on:click={tryAgain} type="button"
      ><i class="fas fa-repeat" />Try again</button
    >
    <button class="btn btn-primary record" on:click={submitResult} type="button"
      ><i class="fas fa-check" />Submit</button
    >
  {:else if processingStatus === "processing"}
    <button class="btn btn-success submit" disabled type="button">
      <i class="fas fa-hourglass-half spin" />Submitting
    </button>
  {:else if processingStatus === "finished"}
    <button
      class="btn btn-success record"
      style="display: none;"
      disabled
      type="button"><i class="fas fa-check" />Finished</button
    >

    <button class="btn btn-primary record" on:click={tryAgain} type="button"
      ><i class="fas fa-repeat" />Try again</button
    >
    {#if loginDetail.lessonId}
      <button class="btn btn-primary record" on:click={openLesson} type="button"
        ><i class="fas fa-arrow-right" />Next Exercise</button
      >
    {/if}
    <button class="btn btn-primary record" on:click={nextExercise} type="button"
      ><i class="fas fa-arrow-right" />Next Exercise</button
    >
  {:else if processingStatus === "failure"}
    <div class="alert alert-danger" role="alert">
      Did you say something? Please check your microphone and try again.
    </div>
  {:else if processingStatus === "error"}
    <button class="btn btn-info" on:click={submitResult} type="button"
      ><i class="fas fa-check" />Resubmit</button
    >
  {/if}
{:else}
  <!-- <button class="btn btn-light" disabled type="button"><i
            class="fas fa-times" />No Data</button> -->
{/if}

<style>
  button {
    padding-right: 8px;
    font-size: 32px;
  }
  i {
    padding-right: 8px;
  }

  .spin {
    animation-name: stretch;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes stretch {
    50% {
      color: black;
    }
  }

  .record {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #00a094;
    color: #ffffff;
    border-color: #00a094;
  }

  .submit {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    line-height: 1.6em;

    border-radius: 16px;

    background: #00a094;
    color: #ffffff;
    border-color: #00a094;
  }
</style>
