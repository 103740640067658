<script>
    import { onMount } from "svelte";

    var audioContext = null;
    var meter = null;
    var canvasContext = null;
    var WIDTH = 500;
    var HEIGHT = 50;
    var rafID = null;
    var mediaStreamSource = null;
    let volumeValue;

    onMount(() => {
        canvasContext = document.getElementById("meter").getContext("2d");
    });

    export function startMeter() {
        // grab an audio context
        audioContext = new AudioContext();

        // Attempt to get audio input
        navigator.mediaDevices
            .getUserMedia({
                audio: {
                    mandatory: {
                        googEchoCancellation: "false",
                        googAutoGainControl: "false",
                        googNoiseSuppression: "false",
                        googHighpassFilter: "false",
                    },
                    optional: [],
                },
            })
            .then((stream) => {
                // Create an AudioNode from the stream.
                mediaStreamSource =
                    audioContext.createMediaStreamSource(stream);

                // Create a new volume meter and connect it.
                meter = createAudioMeter(audioContext);
                mediaStreamSource.connect(meter);

                // kick off the visual updating
                drawLoop();
            })
            .catch((err) => {
                // always check for errors at the end.
                console.error(`${err.name}: ${err.message}`);
                alert("Stream generation failed.");
            });

        console.log("start mic");
    }

    function drawLoop(time) {
        // clear the background
        canvasContext.clearRect(0, 0, WIDTH, HEIGHT);

        // check if we're currently clipping
        if (meter.checkClipping()) canvasContext.fillStyle = "red";
        else canvasContext.fillStyle = "green";

        // draw a bar based on the current volume
        canvasContext.fillRect(0, 0, meter.volume * WIDTH * 1.4, HEIGHT);

        // set up the next visual callback
        rafID = window.requestAnimationFrame(drawLoop);
    }

    function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
        var processor = audioContext.createScriptProcessor(512);
        processor.onaudioprocess = volumeAudioProcess;
        processor.clipping = false;
        processor.lastClip = 0;
        processor.volume = 0;
        processor.clipLevel = clipLevel || 0.98;
        processor.averaging = averaging || 0.95;
        processor.clipLag = clipLag || 750;

        // this will have no effect, since we don't copy the input to the output,
        // but works around a current Chrome bug.
        processor.connect(audioContext.destination);

        processor.checkClipping = function () {
            if (!this.clipping) return false;
            if (this.lastClip + this.clipLag < window.performance.now())
                this.clipping = false;
            return this.clipping;
        };

        processor.shutdown = function () {
            this.disconnect();
            this.onaudioprocess = null;
        };

        return processor;
    }

    function volumeAudioProcess(event) {
        var buf = event.inputBuffer.getChannelData(0);
        var bufLength = buf.length;
        var sum = 0;
        var x;

        // Do a root-mean-square on the samples: sum up the squares...
        for (var i = 0; i < bufLength; i++) {
            x = buf[i];
            if (Math.abs(x) >= this.clipLevel) {
                this.clipping = true;
                this.lastClip = window.performance.now();
            }
            sum += x * x;
        }

        // ... then take the square root of the sum.
        var rms = Math.sqrt(sum / bufLength);

        // Now smooth this out with the averaging factor applied
        // to the previous sample - take the max here because we
        // want "fast attack, slow release."
        this.volume = Math.max(rms, this.volume * this.averaging);
        volumeValue = this.volume;
    }
</script>

<div class="row justify-content-md-center mt-4">
    <div class="col col-md-auto">
        <div>
            Volume: {#if meter?.checkClipping()}TOO_LOUD{:else if volumeValue < 0.002}TOO_QUIET{:else if volumeValue > 0.5}TOO_LOUD{:else}OK{/if}
        </div>
    </div>
</div>
<div class="row justify-content-md-center mt-4">
    <div class="col col-md-auto">
        <div>
            <canvas id="meter" width="50" height="20"></canvas>
        </div>
    </div>
</div>

