<script>
    import { _ } from "svelte-i18n";
    import Loader from '../../components/Loader.svelte'
    import { onMount } from "svelte";
    import { getData } from '../../utils/ApiUtils.svelte'
    import {dynamicSort} from "../../utils/FormatUtils.svelte"
    import {
      generateUUID,
      formatNumberWith4Colors,
      average
    } from "../../utils/FormatUtils.svelte";
   

    import IconExercise from "./IconExercise.svelte";

    // export let studentAccountId;

    let cMap = new Map();

    let cMap2 = new Map();

    let lMap = new Map();
    let eMap = new Map();
    let cGroups = {};
    let eGroups = {};

    let dataStatus = "new";

    onMount(async () => {
        getStudentAccountReport();
    });

    function getStudentAccountReport() {
        getData(`studentapi/report`)
            .then((data) => {
                // console.log('getStudentDetails successful', data)

                data.forEach((e) => {
                    cMap.set(e.idCourse, e.courseName);
                    lMap.set(e.idLesson, e.lessonName);
                    eMap.set(e.idExercise, e.title);
                });

                [...cMap.keys()].forEach((k) => {
                    let obj = data
                        .filter((v) => v.idCourse === k)
                        .reduce((r, a) => {
                            const k = a.idLesson; //{ idCourse: a.idCourse, courseName: a.courseName };
                            r[k] = r[k] || [];
                            r[k].push(a);
                            return r;
                        }, Object.create(null));
                    let avgScoreAllEx = average(
                        data
                            .filter((v1) => v1.idCourse === k && v1?.maxScore)
                            .map((v2) => v2?.maxScore),
                    );

                    let lessonMap2 = new Map();

                    Object.entries(obj).forEach((v) => {
                        let avgScoreLesson = getScore(v[1]);
                        let lessonDetail = {
                            lessonData: [...v[1]],
                            avgScoreLesson: avgScoreLesson,
                        };
                        lessonMap2.set(v[0], lessonDetail);
                    });

                    console.log("xxx", lessonMap2);

                    let lessonMap = new Map(Object.entries(obj));

                    cMap2.set(k, {
                        courseName: cMap.get(k),
                        lesson: lessonMap2,
                        avgScoreAllEx: avgScoreAllEx ? avgScoreAllEx : "",
                    });
                });

                cGroups = data.reduce((r, a) => {
                    const k = a.idCourse; //{ idCourse: a.idCourse, courseName: a.courseName };
                    r[k] = r[k] || [];
                    r[k].push(a);
                    return r;
                }, Object.create(null));

                eGroups = data.reduce((r, a) => {
                    const k = a.idExercise; //{ idCourse: a.idCourse, courseName: a.courseName };
                    r[k] = r[k] || [];
                    r[k].push(a);
                    return r;
                }, Object.create(null));

                console.log("x", cMap, cMap2, lMap, eMap, cGroups, eGroups);

                dataStatus = "ok";
            })
            .catch((error) => {
                dataStatus = "error";
                console.log('error report', error)
            });
    }

    function getScore(l) {
        let avgScoreLessonEx = average(
            l.filter((v1) => v1?.maxScore).map((v2) => v2?.maxScore),
        );
        return avgScoreLessonEx;
    }

    function classBasedOnScore(score) {
        if (score >= 90) {
            return "excellent";
        } else if (score >= 80) {
            return "good";
        } else if (score >= 60) {
            return "poor";
        }
        return "bad";
    }
</script>

<div class="s-content-spacing">
    <div class="card shadow">
        <div class="card-body">
            {#if dataStatus === "ok"}
                {#each [...cMap2] as [idCourse, c]}
                    <h4>
                        <div class="result">
                            <span
                                class="mb-1 result-{classBasedOnScore(
                                    formatNumberWith4Colors(c?.avgScoreAllEx),
                                )}"
                                >{formatNumberWith4Colors(
                                    c?.avgScoreAllEx,
                                )}</span
                            >
                            <span class="ms-1 mb-1 score-spacer"></span>
                            <a class="ms-1" href="/#/course/{idCourse}"
                                >{c?.courseName}
                            </a>
                        </div>
                    </h4>
                    <div>
                        {#each [...c?.lesson] as [idLesson, l]}
                            {#if Number(idLesson)}
                                <h6>
                                    <a
                                        href="/#/lesson/{idLesson}"
                                        >{lMap.get(Number(idLesson))}</a
                                    >
                                </h6>

                                <div class="result">
                                    <span
                                        class="ms-1 mb-1 result-{classBasedOnScore(
                                            formatNumberWith4Colors(
                                                l?.avgScoreLesson,
                                            ),
                                        )}"
                                        >{formatNumberWith4Colors(
                                            l?.avgScoreLesson,
                                        )}</span
                                    >
                                    <span class="ms-1 mb-1 score-spacer"></span>
                                    {#each [...l?.lessonData] as e}
                                        <span class="ms-1 mb-1">
                                            <!-- <span class="tooltiptext">{e?.title}</span> -->

                                            {#if e?.recordingCount > 0}
                                                {#if e?.prBestId}
                                                    <a
                                                        href="/#/recording/{e?.prBestId}"
                                                    >
                                                        <div
                                                            class="result-{classBasedOnScore(
                                                                formatNumberWith4Colors(
                                                                    e?.maxScore,
                                                                ),
                                                            )}"
                                                        >
                                                            {formatNumberWith4Colors(
                                                                e?.maxScore,
                                                            )}
                                                        </div>
                                                    </a>
                                                {:else if e?.maxScore > 50}
                                                    <div class="result-good">
                                                        {formatNumberWith4Colors(
                                                            e?.maxScore,
                                                        )}
                                                    </div>
                                                {:else}
                                                    <div class="result-bad">
                                                        {formatNumberWith4Colors(
                                                            e?.maxScore,
                                                        )}
                                                    </div>
                                                {/if}
                                            {:else}
                                                <a
                                                    href="/#/exercise-open/{e?.key}"
                                                >
                                                    <div class="no-result">
                                                        <IconExercise />
                                                    </div>
                                                </a>
                                            {/if}
                                        </span>

                                        <span style="display:none"
                                            >{e?.title}({e?.idExercise}) - ({e.be})
                                            <span> </span>
                                        </span>
                                    {:else}{$_("NO_EXERCISE")}
                                    {/each}
                                </div>
                            {:else}{$_("NO_DATA")}
                            {/if}
                        {:else}{$_("NO_LESSON")}
                        {/each}
                    </div>
                    <hr/>
                {:else}{$_("NO_COURSE")}
                {/each}
            {/if}
        </div>
    </div>
</div>

<style>
    .result {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .score-spacer {
        width: 5px;
        background-color: black;
    }

    .summary {
        border-right: 2px black solid;
    }

    .result .result-excellent {
        background-color: #008154;
        color: aliceblue;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .result-good {
        background-color: #40ac86;
        color: aliceblue;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .result-poor {
        background-color: #f7ba31;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .result-bad {
        background-color: #fe5b5b;
        color: aliceblue;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    .result .no-result {
        background-color: grey;
        min-width: 1.5em;
        min-height: 1.5em;
    }

    a {
        font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #1a9e94;
    opacity: 0.9;
    cursor: pointer;
    }

    /* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
} */
</style>

