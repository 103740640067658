<script>
  import { _ } from 'svelte-i18n'
  import { router } from 'tinro'
  import { loginToken, userDetails } from '../components/DataStore'
  import { onMount } from 'svelte'

  import { getProfile } from '../utils/ApiCalls.svelte'
  import { postData } from '../utils/ApiUtils.svelte'
  import { setCookie } from '../utils/CookieUtils.svelte'

  let loginName = ''
  let password = ''
  let show_password = false

  $: type = show_password ? 'text' : 'password'
  $: style = show_password ? 'letter-spacing: 0' : 'letter-spacing: 0.3rem;'

  window.handleToken = (e) => {
    console.log('handleToken', e)
    loginGoogle(e)
  }

  onMount(() => {
    console.log(444)
  })

  function initializeLogin() {
    console.log(445)
  }

  function loginGoogle(e) {
    console.log('loginGoogle', e)

    console.log('loginGoogle', loginName, password)
    loginAtemptResult = 'processing'

    let loginJson = {
      credential: e.credential,
    }

    postData('student/authenticateGoogle', loginJson, false)
      .then((data) => {
        console.log('login successful', data)
        // Save data to sessionStorage
        //sessionStorage.setItem("jwtToken", data?.token);
        setCookie('jwtToken', data?.token, 1)
        loginToken.update((x) => data?.token)
        //moveToPage('index.html');
        loginAtemptResult = 'ok'
        getProfile().then(() => {
          router.goto('/')
        })
      })
      .catch((error) => {
        console.log('error login', error)
        //this.failed = true;
        loginAtemptResult = 'errorGoogle'
      })
  }

  let loginAtemptResult = undefined

  function loginAction() {
    loginName = loginName?.trim()
    loginName = loginName?.toLowerCase()
    loginAtemptResult = 'processing'

    mixpanel.track('Student Login Button Clicked', { email: loginName })
    mixpanel.identify(loginName)
    mixpanel.people.set_once({
      $email: loginName,
      user_role: 'student',
    })

    let loginJson = {
      username: loginName,
      password: password,
    }

    postData('student/authenticate', loginJson, false)
      .then((data) => {
        console.log('login successful', data)
        // Save data to sessionStorage
        //sessionStorage.setItem("jwtToken", data?.token);
        setCookie('jwtToken', data?.token, 1)
        loginToken.update((x) => data?.token)
        //moveToPage('index.html');
        loginAtemptResult = 'ok'
        getProfile()
        router.goto('/')
      })
      .catch((error) => {
        console.log('error login', error)
        loginAtemptResult = 'error'
      })
  }

  function resetPasswordAction() {
    router.goto('/reset-password')
  }
</script>

<svelte:head>
  <script
    src="https://accounts.google.com/gsi/client"
    async
    defer
    on:load={initializeLogin}
  ></script>
</svelte:head>

<div class="bg-primary">
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="card shadow-lg border-0 rounded-lg mt-5">
                <div class="card-header text-center">
                  <a
                    href="https://www.oksensay.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><img
                      class="header-logo"
                      alt="logo"
                      src="assets/img/logo_3.png"
                    /></a
                  >
                  <h3 class=" font-weight-light my-4">
                    {$_('STUDENT_LOGIN')}
                  </h3>
                </div>
                <div class="card-body">
                  <form on:submit={loginAction}>
                    <div class="form-floating mb-3">
                      <input
                        class="form-control"
                        id="inputEmail"
                        type="email"
                        placeholder="name@example.com"
                        autocomplete="email"
                        bind:value={loginName}
                      />
                      <label for="inputEmail">{$_('EMAIL_ADDRESS')}</label>
                    </div>
                    <div class="form-floating mb-3">
                      {#if type === 'password'}
                        <input
                          class="form-control"
                          id="inputPassword"
                          type="password"
                          {style}
                          placeholder="Password"
                          autocomplete="current-password"
                          bind:value={password}
                        />
                      {:else}
                        <input
                          class="form-control"
                          id="inputPassword"
                          type="text"
                          {style}
                          placeholder="Password"
                          autocomplete="current-password"
                          bind:value={password}
                        />
                      {/if}
                      <label for="inputPassword">{$_('PASSWORD')}</label>
                      <div
                        class="password-visibility"
                        on:click={() => (show_password = !show_password)}
                      >
                        <span class="password-show-hide">
                          {#if show_password}
                            {$_('HIDE')}
                          {:else}
                            {$_('SHOW')}
                          {/if}
                        </span>
                      </div>
                    </div>
                    <div class="mb-4">
                      <div class="float-end">
                        <span
                          class="btn btn-link"
                          on:click={resetPasswordAction}
                          >{$_('RESET_PASSWORD.FORGET_PASSWORD')}</span
                        >
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between mt-4 mb-0"
                    >
                      <span class="btn btn-primary" on:click={loginAction}
                        >{$_('LOGIN')}</span
                      >
                    </div>
                    <div class="or">{$_('OR')}</div>
                    {#key $loginToken}
                      <div class="text-center s-google-login">
                        <div
                          id="g_id_onload"
                          data-client_id="113347031194-hjljl56cjs8a948p8rcl6o2rsodmafhm.apps.googleusercontent.com"
                          data-context="signin"
                          data-ux_mode="popup"
                          data-callback="handleToken"
                          data-auto_prompt="false"
                        />
                        <div
                          class="g_id_signin"
                          data-type="standard"
                          data-shape="rectangular"
                          data-theme="outline"
                          data-text="signin_with"
                          data-width="300"
                          data-size="large"
                          data-logo_alignment="left"
                        />
                      </div>
                    {/key}
                  </form>
                </div>
                <div class="card-footer text-center py-3">
                  <div class="small">
                    <span
                      class="btn btn-link"
                      on:click={() => {
                        router.goto('/registration')
                      }}>{$_('NEED_ACCOUNT_SIGN_UP')}</span
                    >
                  </div>
                  {#if loginAtemptResult === 'error'}
                    <div class="alert alert-danger mt-2" role="alert">
                      {$_('LOGIN_WRONG_CREDENTIALS')}
                    </div>
                  {:else if loginAtemptResult === 'errorGoogle'}
                    <div class="alert alert-danger mt-2" role="alert">
                      {$_('LOGIN_ERROR_GOOGLE')}
                    </div>
                  {:else if loginAtemptResult === 'processing'}
                    <div class="alert alert-info mt-2" role="alert">
                      {$_('PROCESSING')}
                    </div>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="layoutAuthentication_footer">
      <footer class="py-4 bg-light mt-auto">
        <div class="container-fluid px-4">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">
              Copyright &copy; <a
                href="https://www.oksensay.com/"
                target="_blank"
                rel="noopener noreferrer">Sensay</a
              > 2023
            </div>
            <!-- <div>
              <a href="#">Privacy Policy</a>
              &middot;
              <a href="#">Terms &amp; Conditions</a>
            </div> -->
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>

<style>
  .header-logo {
    width: 100%;
  }

  .password-visibility {
    height: 1.25rem;
    width: 3rem;

    float: right;
    transform: translate(-2.5em, -2.5rem);

    cursor: pointer;
  }

  .password-show-hide {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;

    text-decoration-line: underline;
    text-transform: uppercase;

    color: rgba(50, 50, 50, 0.8);

    padding: 0; /* reset */
    padding-bottom: 92%;
  }

  .s-google-login {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .or {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    text-align: center;
    color: rgba(17, 17, 17, 0.4);
  }
</style>
