<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import Loader from "../../components/Loader.svelte";
  import { getData } from "../../utils/ApiUtils.svelte";
  import { onMount } from "svelte";
  import {
    dynamicSort,
    average,
    formatNumberWith4Colors,
  } from "../../utils/FormatUtils.svelte";

  export let lessonId;

  let lessonsDetail;
  let lessonStatus = "loading";
  let exerciseList = [];
  let exerciseStatus = "loading";

  let avgScoreLessonEx = 0;
  let progress = 0;

  onMount(async () => {
    getLessonById(lessonId);
    getExerciseByLessonId(lessonId);
  });

  function getLessonById(lessonId) {
    getData("studentapi/lessons/" + lessonId)
      .then((data) => {
        console.log("lessons successful", data);

        lessonsDetail = data;
        lessonsDetail["activeState"] = checkActiveState(
          lessonsDetail?.activeDateStart,
          lessonsDetail?.activeDateEnd,
        );
        lessonStatus = "ok";
      })
      .catch((error) => {
        lessonStatus = "error";
        console.log("error", error);
      });
  }

  function checkActiveState(start, end) {
    let now = new Date();
    let startDate = new Date(Date.parse(start));
    let endDate = new Date(Date.parse(end));

    if (startDate > now) {
      return "TOO_EARLY";
    }
    if (now > endDate) {
      return "TOO_LATE";
    }
    return "OK";
  }

  function getExerciseByLessonId(lessonId) {
    getData("studentapi/lessons/" + lessonId + "/overview")
      .then((data) => {
        console.log("lessons successful", data);

        exerciseList = data?.exerciseList.sort(dynamicSort("name"));

        avgScoreLessonEx = average(data?.exerciseList.filter((v1) => v1?.overallScore).map((v2) => v2?.overallScore),);

        exerciseStatus = "ok";
      })
      .catch((error) => {
        exerciseStatus = "error";
        console.log("error", error);
      });
  }

  function viewExerciseDetail(id) {
    mixpanel.track("Student Opens Exercise", { exerciseID: id });
    console.log("viewLessonDetail", id);
    router.goto("/exercise-open/" + id);
  }

  function viewRecordingDetail(id) {
    console.log("viewRecordingDetail", id);
    router.goto(`/recording/${id}`);
  }
</script>

<main>
  <div class="container-fluid px-4">
    <ol class="breadcrumb mb-4">
      <li
        class="breadcrumb-item"
        style="cursor:pointer"
        on:click={() => {
          router.goto("/course-list");
        }}
      >
        {$_("COURSE_LIST")}
      </li>
      {#if lessonsDetail}
        <li
          class="breadcrumb-item"
          style="cursor:pointer"
          on:click={() => {
            router.goto("/course/" + lessonsDetail.courseId);
          }}
        >
          {lessonsDetail?.courseName}
        </li>
        <li class="breadcrumb-item active">
          {lessonsDetail?.name}
        </li>{/if}
    </ol>
    {#if lessonStatus === "loading" && exerciseStatus === "loading"}
      <Loader />
    {:else}
      <div class="row">
        {#if lessonStatus === "loading"}
          <Loader />
        {:else if lessonStatus === "error"}
          {$_("ERROR_LOADING_LESSON")}
        {:else if lessonsDetail}
          <h2>{lessonsDetail.name}</h2>
          <pre class="lesson-description">{lessonsDetail.description}</pre>
          {#if lessonsDetail?.activeDateStart || lessonsDetail?.activeDateEnd}
            <div>
              {#if lessonsDetail.activeState === "TOO_EARLY" && lessonsDetail?.activeDateStart}
                {$_("AVAILABLE_FROM")}
                {new Date(
                  Date.parse(lessonsDetail?.activeDateStart),
                ).toLocaleString()}
              {/if}
              {#if lessonsDetail?.activeDateEnd}
                <br />
                {$_("AVAILABLE_UNTIL")}
                {new Date(
                  Date.parse(lessonsDetail?.activeDateEnd),
                ).toLocaleString()}
              {/if} - {#if lessonsDetail.activeState === "OK"}{$_(
                  "OK",
                )}{:else if lessonsDetail.activeState === "TOO_LATE"}
                {$_("EXPIRED")}
              {:else if lessonsDetail.activeState === "TOO_EARLY"}
                {$_("NOT_YET_AVAILABLE")}
              {/if}
            </div>
          {/if}
          <div>{$_("OVERALL_SCORE")}: {formatNumberWith4Colors(avgScoreLessonEx)}</div>
          <hr />
        {/if}
      </div>

      <div class="row">
        {#if exerciseStatus === "loading"}
          <Loader />
        {:else if exerciseStatus === "error"}
          {$_("ERROR_LOADING_EXERCISE_LIST")}
        {:else}
          {#each exerciseList as e, i}
            <div class="col-xl-3 col-md-6">
              <div
                class="card mb-4"
                on:click={() => {
                  viewExerciseDetail(e.exerciseKey);
                }}
              >
                <div class="card-header" class:done={e.overallScore}>
                  <div class="mt-1" style="display: flex; justify-content:space-between">
                  <span>E{i + 1}: {e.exerciseTitle}</span>
                  <a on:click|stopPropagation href="/#/recording/{e.publicRecordingId}"><span class="badge bg-secondary">{formatNumberWith4Colors(e.overallScore)}</span></a>
                </div>
                </div>
                <div class="card-body exercise-card-body">
                  <ul>
                    <li>
                      {$_("EXERCISE_TYPE")}:
                      <strong>{$_("EXERCISE_TYPES." + e.exerciseType)}</strong>
                    </li>

                    {#if e.overallScore}<li>
                        {$_("BEST_SCORE")}:
                        <a on:click|stopPropagation href="/#/recording/{e.publicRecordingId}"><strong>{e.overallScore?.toFixed(0)}</strong></a>
                      </li>{/if}
                  </ul>
                </div>
              </div>
            </div>
          {:else}
            <div class="row" style="padding-left:15%; padding-right:15%;">
              <svg
                class="text-center"
                width="201"
                height="200"
                viewBox="0 0 201 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M152.5 154.07L107.62 138.07H66.7002V132.15L66.8802 121.29L83.0502 114.64L128.71 112.73L133.5 96.53V71.8L135.4 68.94L149.53 68.54C153.448 68.5558 157.201 70.1191 159.971 72.8894C162.741 75.6596 164.304 79.4124 164.32 83.33V123.25C164.313 126.655 163.134 129.954 160.982 132.593C158.829 135.231 155.834 137.049 152.5 137.74V154.07Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M42.83 131.46V115.2C39.4847 114.507 36.4808 112.681 34.3256 110.03C32.1704 107.379 30.9958 104.066 31 100.65V60.7197C31.0184 56.8028 32.5826 53.0516 35.3522 50.2819C38.1219 47.5122 41.8731 45.9481 45.79 45.9297H125.64C129.556 45.9507 133.306 47.5157 136.075 50.2848C138.844 53.0539 140.409 56.8036 140.43 60.7197V100.65C140.409 104.565 138.844 108.313 136.074 111.081C133.305 113.849 129.555 115.411 125.64 115.43H87.71L42.83 131.46ZM45.83 51.8497C43.4799 51.8602 41.2288 52.7978 39.566 54.4587C37.9033 56.1195 36.9632 58.3695 36.95 60.7197V100.65C36.9632 103 37.9033 105.25 39.566 106.911C41.2288 108.572 43.4799 109.509 45.83 109.52H48.83V123.06L86.76 109.52H125.76C128.109 109.509 130.359 108.571 132.02 106.91C133.682 105.249 134.619 102.999 134.63 100.65V60.7197C134.619 58.3705 133.682 56.1205 132.02 54.4593C130.359 52.7981 128.109 51.8602 125.76 51.8497H45.83Z"
                  fill="#707070"
                />
                <path
                  d="M158.17 152.16L113.29 136.16H72.4004V130.24H114.33L152.26 143.79V130.22H155.26C157.611 130.209 159.862 129.272 161.524 127.611C163.187 125.95 164.127 123.7 164.14 121.35V81.4196C164.127 79.0695 163.187 76.8195 161.524 75.1586C159.862 73.4977 157.611 72.5602 155.26 72.5497H152.26V66.6396H155.26C159.168 66.6685 162.906 68.2363 165.665 71.0029C168.424 73.7696 169.982 77.5123 170 81.4196V121.35C169.993 124.756 168.812 128.056 166.658 130.695C164.504 133.334 161.507 135.151 158.17 135.84V152.16Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M56.1396 66.6396H118.25V72.5497H56.1396V66.6396Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M56.1396 88.8203H88.6696V94.7303H56.1396V88.8203Z"
                  fill="#707070"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M97.5 88.8203H107.85V94.7303H97.5V88.8203Z"
                  fill="#707070"
                />
              </svg>

              <h3 class="text-center">
                {$_("EXERCISE_TABLE_EMPTY_PROMPT_EMPTY")}
              </h3>
            </div>
          {/each}
        {/if}
      </div>
    {/if}
  </div>
</main>

<style>
  .done {
    background-color: #00a094ff;
    color: #f4f4f4;
  }

  .exercise-card-body {
    min-height: 6rem;
  }

  .lesson-description {
    font-family: var(--bs-body-font-family);
    white-space: pre-wrap;
  }

  .card {
    cursor: pointer;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  }
</style>
