<script>
  export let resultData
  export let recordingDataDetail

  export let displayConfig = {
    radar: true,
    smile: true,
    studentScore: true,
    teacherScore: true,
    text: true,
    breakdownWord: true,
    breakdownPhoneme: true,
    debug: true,
  }

  const scoreThreshold = 60
  const starLevels = {
    0: 10,
    1: 30,
    2: 40,
    3: 65,
    4: 80,
    5: 90,
  }

  $: if (resultData) {
    calculateResult()
  }

  let resutlAllCorrect = {
    allCount: 0,
    correctCount: 0,
    allCorrect: false,
  }

  function calculateResult() {
    resutlAllCorrect.allCorrect = true

    console.log(444, resultData, recordingDataDetail)

    recordingDataDetail?.NBest[0]?.Words.forEach((w) => {
      resutlAllCorrect.allCount++
      if (w.ErrorType !== 'None' || w.AccuracyScore < scoreThreshold) {
        resutlAllCorrect.allCorrect = false
      } else {
        resutlAllCorrect.correctCount++
      }
    })
  }
</script>

{#if resultData && recordingDataDetail}
  <div class="row resultHeading5">
    <div class="col-lg-6">
      <h5 style="text-align:center;">Results</h5>
    </div>
  </div>

  <div class="row justify-content-lg-center">
    <div class="col-lg-6">
      {#if displayConfig.teacherScore}
        <div class="card">
          <div class="card-body container">
            <div class="row mb-2">
              <div class="score score-overall col-6">Correct:</div>
              <div class="col-6" style="min-width=8em;">
                {#if resutlAllCorrect?.allCorrect}YES{:else}NO{/if}
              </div>
            </div>
            <div class="row mb-2">
              <div class="score score-overall col-6">Correct Score:</div>
              <div class="col-6" style="min-width=8em;">
                {#if resutlAllCorrect}{resutlAllCorrect.correctCount} / {resutlAllCorrect.allCount}{/if}
              </div>
            </div>
            <div class="row mb-2">
              <div class="score score-overall col-6">Overall Score:</div>
              <div class="col-6" style="min-width=8em;">
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[1]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[2]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[3]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[4]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.overallScore) >
                    starLevels[5]}
                />
              </div>
            </div>
            <div class="row">
              <div class="score col-6">Pronunciation:</div>
              <div class="col-6" style="min-width=8em;">
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[1]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[2]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[3]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[4]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.pronScore) >
                    starLevels[5]}
                />
              </div>
            </div>

            <div class="row">
              <div class="score col-6">Fluency:</div>

              <div class="col-6" style="min-width=8em;">
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[1]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[2]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[3]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[4]}
                />
                <span
                  class="fa fa-star"
                  class:checked={Number.parseFloat(resultData?.fluencyScore) >
                    starLevels[5]}
                />
              </div>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </div>

  <div class="row justify-content-lg-center">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          {#each recordingDataDetail?.NBest[0]?.Words as w, i}
            {#if w.AccuracyScore > 80 && w.ErrorType === 'None'}
              <span
                class="badge badge-excellent"
                data-id={i}
                data-word={w.Word}
                data-score={w.SensayWordScore}>{w.Word}</span
              >
            {:else if w.AccuracyScore >= scoreThreshold && w.ErrorType === 'None'}
              <span
                class="badge badge-good"
                data-id={i}
                data-word={w.Word}
                data-score={w.SensayWordScore}>{w.Word}</span
              >
            {:else if w.ErrorType === 'Mispronunciation'}
              <span
                class="badge badge-miss"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {:else if w.ErrorType === 'Omission'}
              <span
                class="badge badge-error"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {:else if w.ErrorType === 'Insertion'}<span
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}
              />
            {:else if w?.ErrorType !== 'None'}
              <span
                class="badge badge-bad"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {:else}
              <span
                class="badge badge-bad"
                data-id={i}
                data-error={w.ErrorType}
                data-word={w.Word}
                data-score={w.SensayWordScore}>X</span
              >
            {/if}
          {/each}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .initWaveFormDone {
    display: none;
  }

  .topSeparator {
    margin-top: 1em;
  }

  .resultFace {
    font-size: 10em;
  }

  .excellent {
    color: #008154;
    font-size: 1em;
  }

  .good {
    color: #40ac86;
    font-size: 1em;
  }

  .poor {
    color: #f7ba31;
    font-size: 1em;
  }

  .bad {
    color: #fe5b5b;
    font-size: 1em;
  }

  .textResultPre {
    font-family: Helvetica;
    font-size: 1.5em;
    overflow: auto;
    white-space: pre-wrap;
  }

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    color: #333;
    border-radius: 10px;
    box-shadow: 2px 2px 1px silver;
  }

  [data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: #ffcc07ff;
    margin-top: -50px;
    margin-left: 20px;
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
  }

  .badge {
    margin-right: 3px;
    font-weight: 500;
    font-size: 80%;
    cursor: pointer;
  }

  .badge-excellent {
    background: #008154ff;
    color: ivory;
  }

  .badge-good {
    background: #40ac86ff;
    color: ivory;
  }

  .badge-fair {
    background: #f7ba31ff;
    color: ivory;
  }

  .badge-bad {
    background: #fe5b5bff;
    color: ivory;
  }

  .badge-error {
    background: #fe5b5bff;
    color: ivory;
    text-decoration: line-through;
  }

  .badge-miss {
    background: #fe5b5bff;
    color: ivory;
    text-decoration-color: ivory;
    text-decoration-line: underline;
    text-decoration: dashed;
  }

  .detailPhonemes td {
    text-align: center;
    padding: 1px;
  }

  .checked {
    color: orange;
  }

  .score-overall {
    font-weight: 600;
    font-size: large;
  }
</style>
