<script>
  import TopNavigation from '../components/TopNavigation.svelte'
  import LeftNavigation from '../components/LeftNavigation.svelte'
  import Footer from '../components/Footer.svelte'
  import { _ } from 'svelte-i18n'

  import { mainMenuToggle } from '../components/DataStore'
  import PremiumView from './Premium/PremiumView.svelte'
  import { onMount } from 'svelte'

  export let gameId
  export let levelId

  let levelList = []
  let levelSettings

  onMount(async () => {
    getLevelSettings(gameId, levelId)
  })

  let loaded = false
  let progress = 0

  function getLevelSettings(gameId, levelId) {
    if (gameId === 'boggleGame') {
      levelList = [
        {
          levelId: '1',
          levelName: 'Level 1',
          difficultySettings: 1,
        },
        {
          levelId: '2',
          levelName: 'Level 2',
          difficultySettings: 2,
        },
      ]
    } else if (gameId === 'rpgGame') {
      levelList = [
        {
          levelId: 1,
          levelName: 'Level 1',
          difficultySettings: 1,
        },
      ]
    } else if (gameId === 'spellingGame') {
      levelList = [
        {
          levelId: 1,
          levelName: 'Animals',
          levelCode: 'animals',
          difficultySettings: 2,
        },
        {
          levelId: 2,
          levelName: 'Level 8',
          levelCode: 'Level8',
          difficultySettings: 3,
        },
      ]
    } else if (gameId === 'wordGame') {
      levelList = [
        {
          levelId: 1,
          levelName: 'Animals',
          levelCode: 'animals',
          difficultySettings: 3,
        },
        {
          levelId: 2,
          levelName: 'Level 1',
          levelCode: 'Level1',
          difficultySettings: 2,
        },
        {
          levelId: 3,
          levelName: 'Level 2',
          levelCode: 'Level2',
          difficultySettings: 2,
        },
        {
          levelId: 4,
          levelName: 'Level 3',
          levelCode: 'Level3',
          difficultySettings: 2,
        },
        {
          levelId: 5,
          levelName: 'Level 4',
          levelCode: 'Level4',
          difficultySettings: 2,
        },
        {
          levelId: 6,
          levelName: 'Level 5',
          levelCode: 'Level5',
          difficultySettings: 3,
        },
        {
          levelId: 7,
          levelName: 'Level 6',
          levelCode: 'Level6',
          difficultySettings: 3,
        },
        {
          levelId: 8,
          levelName: 'Level 7',
          levelCode: 'Level7',
          difficultySettings: 4,
        },
        {
          levelId: 9,
          levelName: 'Level 8',
          levelCode: 'Level8',
          difficultySettings: 4,
        },
        {
          levelId: 10,
          levelName: 'Restaurant',
          levelCode: 'restaurant',
          difficultySettings: 4,
        },
      ]
    }

    levelSettings = levelList.filter((v) => v.levelId == levelId)[0]
    console.log(222, levelSettings, levelId, gameId)
  }

  let progressTimer = setInterval(() => {
    progress = Math.min(95, progress + 10)
    console.log(444, progress)
  }, 1000)

  function loadDone() {
    loaded = true
    progressTimer = null
    progress = 100
  }
</script>

<div class="sb-nav" class:sb-sidenav-toggled={$mainMenuToggle}>
  <TopNavigation />
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <LeftNavigation />
    </div>
    <div id="layoutSidenav_content">
      {#if gameId === 'boggleGame'}
        <div class:hidden={loaded}>
          <div style="width: 100%; height: 100%;">
            {#key progress}
              <div class="progress m-4">
                <div
                  class="progress-bar"
                  style="width: {progress}%;"
                  role="progressbar"
                />
              </div>
            {/key}
          </div>
        </div>
        <div class:hidden={!loaded} style="width: 100%; height: 100%;">
          <iframe
            src={`https://creative-croissant-a105c3.netlify.app/?levelId=${levelSettings?.levelId}&difficultySettings=${levelSettings?.difficultySettings}`}
            allow="microphone * autoplay allow-scripts"
            title="Boggle Game"
            height="100%"
            width="100%"
            on:load={loadDone}
          />
        </div>
      {:else if gameId === 'rpgGame'}
        <div class:hidden={loaded}>
          <div style="width: 100%; height: 100%;">
            {#key progress}
              <div class="progress m-4">
                <div
                  class="progress-bar"
                  style="width: {progress}%;"
                  role="progressbar"
                />
              </div>
            {/key}
          </div>
        </div>
        <div class:hidden={!loaded} style="width: 100%; height: 100%;">
          <iframe
            src="https://darling-tartufo-78ce2f.netlify.app/"
            allow="microphone * autoplay allow-scripts"
            title="RPG Game"
            height="100%"
            width="100%"
            on:load={loadDone}
          />
        </div>
      {:else if gameId === 'spellingGame'}
        <div class:hidden={loaded}>
          <div style="width: 100%; height: 100%;">
            {#key progress}
              <div class="progress m-4">
                <div
                  class="progress-bar"
                  style="width: {progress}%;"
                  role="progressbar"
                />
              </div>
            {/key}
          </div>
        </div>
        <div class:hidden={!loaded} style="width: 100%; height: 100%;">
          <iframe
            src={`https://charming-klepon-7ee882.netlify.app/?gameLevel=${levelSettings?.levelCode}`}
            allow="microphone * autoplay allow-scripts"
            title="Spelling Game"
            height="100%"
            width="100%"
            on:load={loadDone}
          />
        </div>
      {:else if gameId === 'wordGame'}
        <div class:hidden={loaded}>
          <div style="width: 100%; height: 100%;">
            {#key progress}
              <div class="progress m-4">
                <div
                  class="progress-bar"
                  style="width: {progress}%;"
                  role="progressbar"
                />
              </div>
            {/key}
          </div>
        </div>
        <div class:hidden={!loaded} style="width: 100%; height: 100%;">
          <iframe
            src={`https://resilient-cocada-088f7d.netlify.app/?gameLevel=${levelSettings?.levelCode}`}
            allow="microphone * autoplay allow-scripts"
            title="Spelling Game"
            height="100%"
            width="100%"
            on:load={loadDone}
          />
        </div>
      {:else}
        {$_('NO_GAME')}
      {/if}
      <!-- <Footer /> -->
    </div>
  </div>
</div>

<style>
  iframe {
    /* width: 100%; */
    /* min-height: 595px; */
    border: none;
  }

  .hidden {
    display: none;
  }
</style>
