<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import ExerciseDetail from "./ExerciseDetail.svelte";
  import { userDetails } from "../../components/DataStore";

  export let inputPin;
  export let lessonId;

  let loginDetail = {
    pin: inputPin,
    // userName: $userDetails?.login,
    userName: $userDetails?.firstName + " " + $userDetails?.lastName,
    loginToken: "TOKEN_TEST",
  };

  function handleMessage(event) {
    console.log(event);

    loginDetail = event.detail;

    if ((Number(loginDetail?.pin) && loginDetail?.pin?.length !== 6) || loginDetail?.pin?.length === 5) {
      // status = "LESSON";
      lessonStatus = true;
      let nextLessonId = loginDetail?.pin;
      router.goto("/lesson/" + nextLessonId);
    } else {
      // status = "EXERCISE";
      router.goto("/exercise/" + loginDetail?.pin);
    }

    console.log(loginDetail);
  }

  function handleNextExercise(event) {
    console.log(event);
    router.goto("/lesson/" + lessonId);
  }
</script>

<main>
  {#if $userDetails?.login}
    {#if ((Number(loginDetail?.pin) && loginDetail?.pin?.length !== 6) || loginDetail?.pin?.length === 5)}
      <!-- <LessonSelection lessonId={inputPin} /> -->
      LESSON
    {:else}
      <ExerciseDetail
        on:message={handleMessage}
        on:openLesson={handleMessage}
        on:nextExercise={handleNextExercise}
        exercisePin={inputPin}
        {loginDetail}
      />
    {/if}
  {/if}
</main>
