<script>
    import { _ } from "svelte-i18n";
    import { router } from "tinro";
    import { getData, postData, deleteData } from "../../utils/ApiUtils.svelte";
    import Loader from "../../components/Loader.svelte";
    import { onMount } from "svelte";

    let studentDetails = {};
    let studentStatistics = {};

    let studentDetailsStatus = "loading";
    let studentStatisticsStatus = "loading";

    onMount(async () => {
        getStudentStatistics();
    });

    function getStudentStatistics() {
        getData("studentapi/statistics")
            .then((data) => {
                // console.log('getStudentDetails successful', data)

                studentStatistics = data;
                studentStatisticsStatus = "ok";
            })
            .catch((error) => {
                studentDetailsStatus = "error";
                // console.log('error lessons', error)
            });
    }

    function formatDuration(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds) % 60;

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(remainingSeconds).padStart(2, "0");

        return `${
            hours > 0 ? formattedHours + ":" : ""
        }${formattedMinutes}:${formattedSeconds}`;
    }
</script>

    {#if studentStatisticsStatus == "ok"}
        <div class="s-content-spacing">
            <div class="card shadow mb-4">
                <div class="card-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-8">
                                <div class="mb-1">
                                    {$_("OVERALL_SCORE")}
                                    {studentStatistics?.overallScoreAvg.toFixed(
                                        0,
                                    )}
                                </div>
                                <div class="progress mb-4">
                                    <div
                                        class="progress-bar"
                                        role="progressbar"
                                        style="width: {studentStatistics?.overallScoreAvg.toFixed(
                                            0,
                                        )}%"
                                        aria-valuenow={studentStatistics?.overallScoreAvg.toFixed(
                                            0,
                                        )}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    />
                                </div>
                                <div class="mb-1 small">
                                    {$_("ACCURACY_SCORE")}
                                    {studentStatistics?.accuracyScoreAvg.toFixed(
                                        0,
                                    )}
                                </div>
                                <div class="progress progress-sm mb-2">
                                    <div
                                        class="progress-bar bg-info"
                                        role="progressbar"
                                        style="width: {studentStatistics?.accuracyScoreAvg.toFixed(
                                            0,
                                        )}%"
                                        aria-valuenow={studentStatistics?.accuracyScoreAvg.toFixed(
                                            0,
                                        )}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    />
                                </div>

                                <div class="mb-1 small">
                                    {$_("COMPLETENESS_SCORE")}
                                    {studentStatistics?.completenessScoreAvg.toFixed(
                                        0,
                                    )}
                                </div>
                                <div class="progress progress-sm mb-2">
                                    <div
                                        class="progress-bar bg-info"
                                        role="progressbar"
                                        style="width: {studentStatistics?.completenessScoreAvg.toFixed(
                                            0,
                                        )}%"
                                        aria-valuenow={studentStatistics?.completenessScoreAvg.toFixed(
                                            0,
                                        )}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    />
                                </div>

                                <div class="mb-1 small">
                                    {$_("FLUENCY_SCORE")}
                                    {studentStatistics?.fluencyScoreAvg.toFixed(
                                        0,
                                    )}
                                </div>
                                <div class="progress progress-sm mb-2">
                                    <div
                                        class="progress-bar bg-info"
                                        role="progressbar"
                                        style="width: {studentStatistics?.fluencyScoreAvg.toFixed(
                                            0,
                                        )}%"
                                        aria-valuenow={studentStatistics?.fluencyScoreAvg.toFixed(
                                            0,
                                        )}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    />
                                </div>

                                <div class="mb-1 small">
                                    {$_("PRONUNCIATION_SCORE")}
                                    {studentStatistics?.pronScoreAvg.toFixed(0)}
                                </div>
                                <div class="progress progress-sm mb-2">
                                    <div
                                        class="progress-bar bg-info"
                                        role="progressbar"
                                        style="width: {studentStatistics?.pronScoreAvg.toFixed(
                                            0,
                                        )}%"
                                        aria-valuenow={studentStatistics?.pronScoreAvg.toFixed(
                                            0,
                                        )}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    />
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="row mb-3">
                                    <div class="col">
                                        <div
                                            class="card border-left-secondary shadow h-100 py-2"
                                        >
                                            <div class="card-body">
                                                <div
                                                    class="row no-gutters align-items-center"
                                                >
                                                    <div class="col mr-2">
                                                        <div
                                                            class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                                                        >
                                                            {$_(
                                                                "NUMBER_OF_RECORDINGS",
                                                            )}
                                                        </div>
                                                        <div
                                                            class="h5 mb-0 font-weight-bold text-gray-800"
                                                        >
                                                            {studentStatistics?.publicRecordingCount}
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i
                                                            class="fas fa-clipboard-list fa-2x text-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <div
                                            class="card border-left-info shadow h-100 py-2"
                                        >
                                            <div class="card-body">
                                                <div
                                                    class="row no-gutters align-items-center"
                                                >
                                                    <div class="col mr-2">
                                                        <div
                                                            class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                                                        >
                                                            {$_(
                                                                "TOTAL_WORD_COUNT",
                                                            )}
                                                        </div>
                                                        <div
                                                            class="h5 mb-0 font-weight-bold text-gray-800"
                                                        >
                                                            {studentStatistics?.totalWordCount}
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i
                                                            class="fas fa-comments fa-2x text-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <div
                                            class="card border-left-info shadow h-100 py-2"
                                        >
                                            <div class="card-body">
                                                <div
                                                    class="row no-gutters align-items-center"
                                                >
                                                    <div class="col mr-2">
                                                        <div
                                                            class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                                                        >
                                                            {$_(
                                                                "RECORDING_TIME",
                                                            )}
                                                        </div>
                                                        <div
                                                            class="h5 mb-0 font-weight-bold text-gray-800"
                                                        >
                                                            {formatDuration(
                                                                studentStatistics?.totalDurationSec,
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i
                                                            class="fas fa-comments fa-2x text-gray-300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/if}

<style>
    :global(.recordingRow) {
        cursor: pointer;
    }

    :global(.recordingRow:hover) {
        background-color: whitesmoke;
    }

    .table-responsive {
        overflow: hidden;
    }
</style>
