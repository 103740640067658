<script>
  import { Route, router, meta } from "tinro";
  import { onMount } from "svelte";
  import { getProfile } from "./utils/ApiCalls.svelte";

  import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";
  import { loginToken, userDetails } from "./components/DataStore";

  import Dashboard from "./pages/Dashboard.svelte";
  import Login from "./pages/Login.svelte";
  import Registration from "./pages/Registration.svelte";
  import Settings from "./pages/Settings.svelte";
  import Help from "./pages/Help.svelte";
  import LessonTodo from "./pages/LessonTodo.svelte";
  import ExerciseOpen from "./pages/ExerciseOpen.svelte";
  import CourseList from "./pages/CourseList.svelte";
  import CourseDetail from "./pages/CourseDetail.svelte";
  import LessonDetail from "./pages/LessonDetail.svelte";
  import RecordingList from "./pages/RecordingList.svelte";
  import LoginQrCode from "./pages/LoginQrCode.svelte";
  import RecordingDetail from "./pages/RecordingDetail.svelte";
  import ResetPassword from "./pages/ResetPassword.svelte";
  import OneCampusPage from "./pages/OneCampusPage.svelte";
  import SchoolPlusPage from "./pages/SchoolPlusPage.svelte";

  import en from "./i18n/en.json";
  import zhTW from "./i18n/zh-TW.json";
  import ResetPasswordVerifyPage from "./pages/ResetPasswordVerifyPage.svelte";
  import PremiumPage from "./pages/PremiumPage.svelte";
  import GameDetail from "./pages/GameDetail.svelte";
  import GameLevels from "./pages/GameLevels.svelte";
  import ExamPage from "./pages/ExamPage.svelte";
  import AnalyticsPage from "./pages/AnalyticsPage.svelte";
  import ReportPage from './pages/ReportPage.svelte'
    import CourseEnrollment from "./pages/CourseEnrollment.svelte";
    import TeachingComments from "./pages/TeachingComments.svelte";

  addMessages("en", en);
  addMessages("zh-TW", zhTW);

  router.mode.hash();

  console.log("getLocaleFromNavigator", getLocaleFromNavigator());
  init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
  });

  onMount(() => {
    const url_string = window.location.href;
    if (url_string.match("one-campus")) {
      let urlParams = new URLSearchParams(document.location.search);

      //document.location.search = ''
      if (urlParams.get("dsns")) {
        router.goto(
          `/one-campus?dsns=${urlParams.get("dsns")}&code=${urlParams.get(
            "code",
          )}&role=${urlParams.get("role")}`,
        );
        return;
      }
    }

    if (url_string.match("schoolplus")) {
      let urlParams = new URLSearchParams(document.location.search);
      router.goto(
        `/schoolplus?code=${urlParams.get("code")}&role=${urlParams.get(
          "role",
        )}`,
      );
      return;
    }

    if (url_string.match("exkey")) {
      let urlParams = new URLSearchParams(document.location.search);
      router.goto(`exercise-open/${urlParams.get("exkey")}`);
    }

    getProfile();
  });

  function handleMessage(event) {
    console.log(event);
    router.goto("/" + event.detail);
  }
</script>

<Route>
  <Route path="/login" fallback>
    <Login on:message={handleMessage} />
  </Route>

  <Route path="/loginqrcode/:qrcode" let:meta>
    <LoginQrCode qrcode={meta.params.qrcode} />
  </Route>

  <Route path="/registration" let:meta>
    <Registration on:message={handleMessage} emailParam={meta.query.email} />
  </Route>

  <Route path="/one-campus" let:meta>
    <OneCampusPage
      dsns={meta.query.dsns}
      code={meta.query.code}
      role={meta.query.role}
    />
  </Route>

  <Route path="/schoolplus" let:meta>
    <SchoolPlusPage code={meta.query.code} role={meta.query.role} />
  </Route>

  <Route path="/reset-password">
    <ResetPassword />
  </Route>

  <Route path="/reset-password-verify/:jwtToken" let:meta>
    <ResetPasswordVerifyPage jwtToken={meta.params.jwtToken} />
  </Route>

  {#if $loginToken}
    <Route path="/settings">
      <Settings />
    </Route>

    <Route path="/help">
      <Help />
    </Route>

    <Route path="/lesson-todo">
      <LessonTodo />
    </Route>

    <Route path="/course-list">
      <CourseList />
    </Route>

    <Route path="/course-enrollment/:coursePin" let:meta>
      <CourseEnrollment coursePin={meta.params.coursePin}/>
    </Route>

    <Route path="/recording-list" let:meta>
      <RecordingList courseId={meta.query.courseId} />
    </Route>

    <Route path="/recording/:recordingId" let:meta>
      <RecordingDetail recordingId={meta.params.recordingId} />
    </Route>

    <Route path="/course/:courseId" let:meta>
      <CourseDetail courseId={meta.params.courseId} />
    </Route>

    <Route path="/lesson/:lessonId" let:meta>
      <LessonDetail lessonId={meta.params.lessonId} />
    </Route>

    <Route path="/exercise-open/:inputPin" let:meta>
      <ExerciseOpen inputPin={meta.params.inputPin} />
    </Route>

    <Route path="/premium">
      <PremiumPage />
    </Route>

    <Route path="/exam">
      <ExamPage />
    </Route>

    <Route path="/analytics">
      <AnalyticsPage />
    </Route>

    <Route path="/report">
      <ReportPage />
    </Route>

    <Route path="/teaching-comments">
      <TeachingComments />
    </Route>

    <Route path="/games/:gameId/levels" let:meta>
      <GameLevels gameId={meta.params.gameId} />
    </Route>

    <Route path="/games/:gameId/levels/:levelId" let:meta>
      <GameDetail gameId={meta.params.gameId} levelId={meta.params.levelId} />
    </Route>

    <Route path="/">
      <Dashboard />
    </Route>
    <Route path="/index">
      <Dashboard />
    </Route>
  {/if}
</Route>

<style>
</style>
