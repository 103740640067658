<script>
  export let courseList
  export let rainbowColors = [
    '#DA4038',
    '#E37D42',
    '#F4D965',
    '#549E6F',
    '#82AAD3',
    '#3554EE',
    '#4A2A74',
  ]

  function formatDate(v) {
    var options = {
      weekday: 'short',
      day: 'numeric',
    }
    var day = new Date(Date.parse(v.d))

    console.log(day.toLocaleDateString('en-US', options)) // Saturday, September 17, 2016
    return day.toLocaleDateString('en-US', {
      weekday: 'short',
    })
    // +
    // '<br/>' +
    // day.toLocaleDateString('en-US', {
    //   day: 'numeric',
    // })
  }

  function formatColor(v, i) {
    return v?.v > 0 ? rainbowColors[i] : '#DCDCDC'
  }
</script>

<div class="streak">
  <div class="frame">
    <div class="links">
      {#each courseList as c, i}
        <div class="daybox">
          <svg
            class="svg-icon"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16.0913"
              r="15"
              fill="white"
              stroke="black"
              stroke-opacity="0.9"
              stroke-width="0.25"
              stroke-dasharray="0.1 0.1"
            />
            <circle cx="16" cy="16.0913" r="12" fill={formatColor(c, i)} />
            {#if c?.v > 0}
              <path
                d="M14.375 18.5664L11.8959 16.0872C11.6196 15.811 11.1805 15.811 10.9042 16.0872C10.628 16.3635 10.628 16.8026 10.9042 17.0789L13.8721 20.0468C14.1484 20.323 14.5946 20.323 14.8709 20.0468L22.3792 12.5455C22.6555 12.2693 22.6555 11.8301 22.3792 11.5539C22.103 11.2776 21.6638 11.2776 21.3875 11.5539L14.375 18.5664Z"
                fill="#F2F2F2"
              />
            {/if}
          </svg>
          <div class="days" class:isToday={c?.isToday}>
            {@html formatDate(c)}
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style>
  .daybox {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .isToday {
    font-weight: 700;
  }

  .days {
    flex-direction: column;
  }

  .frame {
    display: flex;
    text-align: center;
    margin: 0px;
    position: relative;
  }
  .links {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .frame:before {
    content: '';
    position: absolute;
    top: 1.5em;
    left: 3em;
    border-top: 1px solid #00000066;
    background: #00000066;
    width: 24em;
    transform: translateY(-50%);
  }

  .svg-icon {
    width: 3em;
    height: 3em;
  }

  @media only screen and (max-device-width: 660px) {
    .frame {
      justify-content: center;
    }
    .daybox {
      margin-left: 0.1em;
      margin-right: 0.1em;
    }
    .svg-icon {
      width: 2.5em;
      height: 2.5em;
    }

    .frame:before {
      top: 1.25em;
      left: 3em;
      width: 15em;
    }
  }
</style>
