<script context="module">
    
import { BASE_URL, userDetails, apiToken, apiDomain } from "../../../../components/DataStore";
import { get } from 'svelte/store';

import ky from "ky";

export async function getNewTokenFn(force = false, region = "ALL") {
    try {
      let apiTokenResult = await ky
        .get(`${BASE_URL}public/api/azureToken4?region=${region}${force? '&force=true': ''}`, {
          retry: {
            limit: 3,
            statusCodes: [400, 429, 502],
          },
          timeout: 30000,
        })
        .json();

        apiToken.set(apiTokenResult?.token)
        apiDomain.set(apiTokenResult?.domain)
        console.log('SetToken', get(apiToken))
        return apiTokenResult?.token;
    } catch (error) {
      processingStatus = "failure";
      let errCode = error?.response?.status;
      let errMsg = await error?.response?.text();
      let errPayload = { code: errCode, msg: errMsg, response: error.response, error: JSON.stringify(error, Object.getOwnPropertyNames(error)) };
      ky.post(BASE_URL + "public/api/log-action", {
        json: {
          source: "S",
          userInfo: get(userDetails).login,
          action: "TOKEN",
          actionStep: "ERROR",
          msg: JSON.stringify(errPayload),
        },
      });
    }
  }
  </script>