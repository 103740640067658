<script>
    import { _ } from "svelte-i18n";
    import { router } from "tinro";
    import Loader from "../../components/Loader.svelte";
    import { getData, postData } from "../../utils/ApiUtils.svelte";
    import { onMount } from "svelte";
    import { userConfig, userDetails } from "../../components/DataStore";

    export let coursePin;

    let dataStatus = "loading";

    let courseDetail;
    let courseStatus = "loading";

    let courseStudentDetail;
    let courseStudentStatus = "loading";

    onMount(async () => {
        getCourseByPin(coursePin);
    });

    function getCourseByPin(coursePin) {
        let coursePinWithPart =
            coursePin.substr(0, 4) + "-" + coursePin.substr(4, 8);
        getData("studentapi/coursesByPin/" + coursePinWithPart)
            .then((data) => {
                console.log("course successful", data);

                courseDetail = data;
                courseStatus = "ok";

                getCourseById(data?.id)
            })
            .catch((error) => {
                courseStatus = "error";
                console.log("error", error);
            });
    }

    function getCourseById(courseId) {
    getData("studentapi/courses2/" + courseId, false, true)
      .then((data) => {
        console.log("course successful", data);

        courseStudentDetail = data;
        courseStudentStatus = "ok";
      })
      .catch((error) => {
        courseStudentStatus = "error";
        console.log("error", error);
      });
  }

    function enrollInCourse() {
        
        console.log("enrollInCourse", courseDetail?.id);
        // router.goto('/course/' + id)

        let enrollInPremiumClassJson = {
            courseId: courseDetail?.id,
            studentAccountId: $userDetails.id,
            source: "COURSE_PIN",
        };

        courseStatus = "processing";

        postData("studentapi/course/enroll", enrollInPremiumClassJson)
            .then((data) => {
                console.log("login successful", data);
                courseStatus = "changed";
                router.goto(`/course/${id}`);
            })
            .catch((error) => {
                console.log("error login", error);
                courseStatus = "error";
                errorMessage = $_("PASSWORD_CHANGE_ERROR");
            });
    }
</script>

<main>
    <div class="container-fluid px-4">
        <ol class="breadcrumb mt-4 mb-4">
            <li
                class="breadcrumb-item"
                style="cursor:pointer"
                on:click={() => {
                    router.goto("/");
                }}
            >
                {$_("COURSE_ENROLLMENT")}
            </li>
            {#if courseDetail?.courseName}<li class="breadcrumb-item active">
                    {courseDetail?.courseName}
                </li>{/if}
        </ol>
        {#if courseStatus === "loading" || courseStudentStatus === "loading" }
            <Loader />
        {:else}
            <div class="row">

                <h3>{$_("COURSE_ENROLLMENT.INSTRUCTIONS")}</h3>

                <h2>{courseDetail.name}</h2>
                <p>{courseDetail.description}</p>
                {#if courseDetail?.organizationName}<p>
                        {$_("ORGANIZATION_NAME")}
                        {courseDetail?.organizationName}
                    </p>
                {/if}
                <p>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.16657 10.9834V13.3251C4.16657 13.9334 4.4999 14.5001 5.03324 14.7917L9.1999 17.0667C9.6999 17.3417 10.2999 17.3417 10.7999 17.0667L14.9666 14.7917C15.4999 14.5001 15.8332 13.9334 15.8332 13.3251V10.9834L10.7999 13.7334C10.2999 14.0084 9.6999 14.0084 9.1999 13.7334L4.16657 10.9834ZM9.1999 2.9334L2.1749 6.76673C1.5999 7.0834 1.5999 7.91673 2.1749 8.2334L9.1999 12.0667C9.6999 12.3417 10.2999 12.3417 10.7999 12.0667L17.4999 8.4084V13.3334C17.4999 13.7917 17.8749 14.1667 18.3332 14.1667C18.7916 14.1667 19.1666 13.7917 19.1666 13.3334V7.99173C19.1666 7.6834 18.9999 7.4084 18.7332 7.2584L10.7999 2.9334C10.2999 2.66673 9.6999 2.66673 9.1999 2.9334Z"
                            fill="#222529"
                        />
                    </svg>
                    {courseDetail?.teacherName}
                </p>
            </div>
            <div class="row">
                <div class="col">
                    {#if courseStudentDetail?.status === 404 }
                    <button class="btn btn-primary" on:click={enrollInCourse}
                        >{$_("COURSE_ENROLLMENT.JOIN")}</button
                    >
                    {:else}

                    {$_("COURSE_ENROLLMENT.ALREADY_ENROLLED")} <a href="#/course/{courseStudentDetail?.courseId}">{courseStudentDetail?.courseName}</a>
                    {/if}
                </div>
            </div>
        {/if}
    </div>
</main>

<style>
</style>
